import { useRef } from 'react';
import { TouchableOpacityProps } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { Container, ButtonText } from './styles';

export interface ButtonProps extends TouchableOpacityProps {
  children: string;
}

export const MenuItem = ({ children, ...rest }: ButtonProps) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
    <Container isSelected ref={ref} isHovered={isHovered} {...rest}>
      <ButtonText isHovered={isHovered}>{children}</ButtonText>
    </Container>
  );
};
