import styled, { css } from 'styled-components';
import { Resizable, ResizableProps } from 'react-resizable';

interface StyledWidgetContentProps {
  isDragPreview: boolean;
  isColliding: boolean;
  isEditMode: boolean;
}

interface CustomResizableProps extends ResizableProps {
  isEditMode: boolean;
}

export const CustomResizable = styled(Resizable)<CustomResizableProps>`
  ${({ isEditMode }) =>
    isEditMode
      ? css`
          border: 1px solid #17a0fb;
          border-radius: 0px !important;
          background: transparent;
        `
      : css``}

  .react-resizable {
    position: relative;
  }
  .react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 10px;
    border: 1px solid #17a0fb;
    background: none;
    background-color: white;
  }
  .react-resizable-handle-se {
    bottom: -5px;
    right: -5px;
  }
  .react-resizable-handle-s {
    bottom: -5px;
    transform: rotate(0deg);
  }
  .react-resizable-handle-e {
    right: -5px;
    transform: rotate(0deg);
  }
  .react-resizable-handle-n {
    top: -5px;
    transform: rotate(0deg);
  }
  .react-resizable-handle-ne {
    top: -5px;
    right: -5px;
  }
  .react-resizable-handle-nw {
    top: -5px;
    left: -5px;
  }
  .react-resizable-handle-w {
    left: -5px;
    transform: rotate(0deg);
  }
  .react-resizable-handle-sw {
    left: -5px;
    bottom: -5px;
  }
`;

const StyledWidgetContent = styled.section<StyledWidgetContentProps>`
  position: relative;
  border-radius: 8px;
  padding: 8px;
  ${({ theme, isColliding, isDragPreview }) =>
    isDragPreview
      ? css`
          background: ${isColliding ? theme.red : theme.blue};
          opacity: 0.6;
          ${isColliding && 'cursor : no-drop !important;'}
        `
      : css`
          background: ${theme.background};
        `};

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    cursor: ${({ isEditMode }) => (isEditMode ? 'grab' : 'auto')};
  }
`;

export { StyledWidgetContent };
