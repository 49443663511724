/* eslint-disable @typescript-eslint/no-empty-function */
import { MenuItem } from '@molecules/Profile/MenuItem';
// import { useTranslation } from 'react-i18next';
import { Container } from './styles';

export const ProfileMenu = () => {
  // const { t } = useTranslation();

  return (
    <Container>
      <MenuItem>My account</MenuItem>
    </Container>
  );
};
