/* eslint-disable @typescript-eslint/no-empty-function */
import { useMemo } from 'react';
import { IWidget, useWidgetGridContext } from '@contexts/WidgetGridContext';
import { WidgetSidePanel } from '@molecules/WidgetSidePanel';
import { Select } from '@atoms/Select';
import { useMutationUpdateWidgets } from '@services/hooks/useMutationUpdateWidgets';

export interface MapData {
  mapStyle: string;
}

export interface MapWidgetinitialData {
  grid_height: 6;
  grid_width: 6;
  data: MapData;
}

export const mapWidgetinitialData: MapWidgetinitialData = {
  grid_height: 6,
  grid_width: 6,
  data: {
    mapStyle: 'mapbox://styles/joaogn/ckq2kyiwc2g1k18qvfownydky',
  },
};

interface WidgetExampleSideBarProps {
  widgetData: IWidget<MapData>;
}

export const MapWidgetSideBar = ({ widgetData }: WidgetExampleSideBarProps) => {
  const { setSidePanelData, updateWidgetData, widgets } =
    useWidgetGridContext();
  const mutationUpdateWidgets = useMutationUpdateWidgets();
  const widget: IWidget<MapData> | undefined = useMemo(
    () => widgets.find(widgetItem => widgetItem.id === widgetData.id),
    [widgets, widgetData.id],
  );
  const onUpdateStyle = async (newStyle: string) => {
    const newData = {
      data: {
        mapStyle: newStyle,
      },
    };

    await mutationUpdateWidgets.mutateAsync([
      {
        id: widgetData.id,
        data: newData.data,
      },
    ]);

    updateWidgetData<MapData>(widgetData.id, newData);
  };

  return (
    <WidgetSidePanel
      title="Map Widget Filters"
      onClose={() => setSidePanelData({ widget: undefined, isOpened: false })}
    >
      {widget && (
        <Select
          onChange={values => onUpdateStyle(values[0])}
          selectedOptions={widget ? [widget.data.mapStyle] : []}
          options={[
            {
              label: 'dark',
              value: 'mapbox://styles/joaogn/ckq2kyiwc2g1k18qvfownydky',
            },
            { label: 'clear', value: 'mapbox://styles/mapbox/light-v10' },
            {
              label: 'satellite',
              value: 'mapbox://styles/mapbox/satellite-v9',
            },
            {
              label: 'streets',
              value: 'mapbox://styles/mapbox/streets-v11',
            },
            {
              label: 'landforms',
              value: 'mapbox://styles/mapbox/outdoors-v11',
            },
          ]}
        />
      )}
    </WidgetSidePanel>
  );
};
