export const baseName = 'New Custom Event Config';
export interface FakeEventWidgetConfigData {
  custom_field_config_id: string | undefined;
}

export const fakeEventWidgetInitialConfigData = {
  name: baseName,
  config: {
    custom_field_config_id: undefined,
  },
};
