export const baseName = 'New Custom Event Config';
export interface CustomEventWidgetConfigData {
  custom_field_config_id: string | undefined;
}

export const customEventWidgetInitialConfigData = {
  name: baseName,
  config: {
    custom_field_config_id: undefined,
  },
};
