/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';

import { Spinner } from '@atoms/Spinner';

import { WorkspacePageAddNewWidgetButton } from '@molecules/WorkspacePage/Button';
import { WorkspacePageAddButton } from '@molecules/WorkspacePage/AddButton';
import { WidgetGrid } from '@organisms/WidgetGrid';

import { useWidgetGridContext, IWidget } from '@contexts/WidgetGridContext';

import { useQueryPage } from '@services/hooks/useQueryPage';
import { useMutationUpdateWidgets } from '@services/hooks/useMutationUpdateWidgets';

import { Container, WidgetsContainer } from './styles';

interface WorkspacePageProps {
  onCreateNewWidget: () => void;
  pageId: string;
}

export const WorkspacePage = ({
  pageId,
  onCreateNewWidget,
}: WorkspacePageProps) => {
  const pageQuery = useQueryPage(pageId);
  const updateWidgetsMutation = useMutationUpdateWidgets();
  const { widgets, updateWidgetList } = useWidgetGridContext();

  const handleSaveWidgets = (updatedWidgets: IWidget[]) => {
    updateWidgetsMutation.mutateAsync(updatedWidgets);
  };

  useEffect(() => {
    updateWidgetList(
      pageQuery.data
        ? pageQuery.data.widgets.map(widget => ({
            ...widget,
            left: 0,
            top: 0,
            width: 0,
            height: 0,
          }))
        : [],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageQuery.data]);

  return (
    <Container>
      <WidgetsContainer>
        {pageQuery.isFetching || pageQuery.isLoading ? (
          <Spinner size="large" />
        ) : widgets.length ? (
          <WidgetGrid onSaveWidgets={handleSaveWidgets} />
        ) : (
          <WorkspacePageAddNewWidgetButton onAddPress={onCreateNewWidget} />
        )}
        {widgets.length ? (
          <WorkspacePageAddButton onAddPress={onCreateNewWidget} />
        ) : null}
      </WidgetsContainer>
    </Container>
  );
};
