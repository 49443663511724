import React, { useRef } from 'react';
import { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { useHover } from 'react-native-web-hooks';
import { EventCardCategory } from '@molecules/EventCardCategory';
import { EventCardCityAndTime } from '@molecules/EventCardCityAndTime';
import { EventCardTags } from '@molecules/EventCardTags';
import { EventCardTypeSource } from '@molecules/EventCardTypeSource';
import { EventCardFooter } from '@molecules/EventCardFooter';
import { Body1, Body2 } from '@nucleus/Typography';
import { Event } from '@services/hooks/useQueryFiltredEvents';
import { EventCardHeader } from '@molecules/EventCardHeader';
import {
  Container,
  ContainerOverlay,
  ContentContainer,
  TypeSourcesContainer,
} from './styles';

interface EventCardProps {
  isActive?: boolean;
  onPress: () => void;
  event: Event;
}

const areEqual = (prevProps: EventCardProps, nextProps: EventCardProps) =>
  prevProps.isActive === nextProps.isActive;

export const EventCard = React.memo(
  ({ isActive, event, onPress }: EventCardProps) => {
    const ref = useRef(null);
    const isHovered = useHover(ref);
    const theme = useTheme();
    const { t } = useTranslation();

    return (
      <Container ref={ref} isHovered={isHovered} onPress={onPress}>
        {isActive && <ContainerOverlay />}
        <EventCardCategory
          name={t(event.category.name)}
          color={event.category.color}
          icon={event.category.icon}
        />

        <ContentContainer>
          <EventCardHeader intensity={event.intensity} />

          {event.title.length < 110 ? (
            <Body1 numberOfLines={2}>{event.title}</Body1>
          ) : (
            <Body2 numberOfLines={2}>{event.title}</Body2>
          )}

          <EventCardCityAndTime
            locationName={event.location_name}
            formattedDate={event.formatted_created_at}
          />

          <EventCardTags tags={event.tags.slice(0, 3)} />

          <TypeSourcesContainer>
            {event.sources_count.map(source => (
              <EventCardTypeSource
                key={`card_source_${source.source_type_name}`}
                name={t(source.source_type_name)}
                badgeValue={String(source.source_type_count)}
                badgeColor={
                  source.source_type_name === 'All'
                    ? theme.yellow
                    : theme.purple
                }
              />
            ))}
          </TypeSourcesContainer>

          <EventCardFooter updatedFormattedDate={event.formatted_updated_at} />
        </ContentContainer>
      </Container>
    );
  },
  areEqual,
);
