import { useTranslation } from 'react-i18next';

import { H6 } from '@nucleus/Typography';

import { DropDown } from '@atoms/DropDown';

import { Container } from './styles';

interface WorkspacesAreaSortByProps {
  onPressOldest: () => void;
  onPressNewest: () => void;
  onPressName: () => void;
}

export const WorkspacesAreaSortBy = ({
  onPressOldest,
  onPressNewest,
  onPressName,
}: WorkspacesAreaSortByProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <H6 style={{ marginRight: 16, color: '#C4C4C4' }}>{t('sort_by')}</H6>
      <DropDown
        iconFill="#C0C0C0"
        options={[
          {
            label: t('oldest'),
            value: 'oldest',
            onPress: onPressOldest,
          },
          {
            label: t('newest'),
            value: 'newest',
            onPress: onPressNewest,
          },
          {
            label: t('name'),
            value: 'name',
            onPress: onPressName,
          },
        ]}
      />
    </Container>
  );
};
