import { Modal } from 'react-native';
// import { Video } from 'expo-av';
import { Video } from '@nucleus/Video';
import { Image } from '@nucleus/Image';
import { Container, ContentContainer, CloseButton } from './styles';
import closeIcon from '../../../assets/icons/close.svg';

interface PreviewModalProps {
  onClose: () => void;
  visible?: boolean;
  source?: string;
  type: 'image' | 'video' | undefined;
}

export const PreviewModal = ({
  onClose,
  visible,
  source,
  type,
}: PreviewModalProps) => {
  return (
    <Modal visible={visible} transparent>
      <Container>
        <ContentContainer>
          <CloseButton icon={closeIcon} onPress={onClose} />
          {source &&
            (type === 'video' ? (
              <Video url={source} />
            ) : (
              <Image source={source} />
            ))}
        </ContentContainer>
      </Container>
    </Modal>
  );
};
