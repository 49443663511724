import { useQuery } from 'react-query';
import { api } from '../userManagementApi';

export interface WidgetType {
  types: WidgetType[];
  id: string;
  name: string;
  slug: string;
  description: string;
  is_configurable: boolean;
  created_at: string;
  updated_at: string;
}

export async function getWigetTypes(): Promise<WidgetType[]> {
  const getWorkspaceResponse = await api.get<WidgetType[]>(`/v1/widget/types`);

  // temporary filter to remove 'custom-event-list' from the list
  return getWorkspaceResponse.data.map(widgetType => {
    if (widgetType.slug === 'list') {
      const newTypes = widgetType.types.filter(
        subWidgetType => subWidgetType.slug !== 'custom-event-list',
      );
      return {
        ...widgetType,
        types: newTypes,
      };
    }
    return widgetType;
  });
}

export function useQueryWidgetTypes() {
  return useQuery(['widgetTypes'], () => getWigetTypes());
}
