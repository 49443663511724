import styled, { css } from 'styled-components/native';
import { Platform } from 'react-native';
import { Body2, ButtonPrimary } from '@nucleus/Typography';

interface ContainerProps {
  error?: boolean;
  hasFocus?: boolean;
}

export const Container = styled.View<ContainerProps>`
  flex-direction: row;
  max-width: 400px;
  width: 95%;
  padding: 8px;
  height: 40px;
  justify-content: flex-start;
  align-items: center;
  ${({ theme, error, hasFocus }) => {
    if (hasFocus) {
      return css`
        border-color: ${theme.white};
      `;
    }
    if (error) {
      return css`
        border-color: ${theme.red};
      `;
    }
    return css`
      border-color: ${theme.purpleBlue};
    `;
  }};
`;

export const ErrorContainer = styled.View`
  top: 40px;
  left: 0;
  flex-direction: row;
  position: absolute;
  align-items: center;
  justify-content: center;
`;

export const ErrorText = styled(ButtonPrimary)`
  color: ${({ theme }) => theme.red};
  margin-left: 4px;
  font-weight: 400;
`;

export const InputContainer = styled.View`
  width: 100%;
  max-width: 290px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2px;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${({ theme }) => theme.purpleBlue};
  padding: 8px;
  height: 40px;
`;

interface IconsContainerProps {
  error?: boolean;
  hasFocus?: boolean;
  withIcon?: boolean;
}

export const IconsContainer = styled.View<IconsContainerProps>`
  flex-direction: row;
  justify-content: ${({ error, withIcon }) => {
    if (error && withIcon) {
      return 'space-between';
    }
    if (error || withIcon) {
      return 'flex-end';
    }
    return 'flex-end';
  }};
  align-items: center;
  padding-left: 5px;

  width: 20px;
`;

export const Label = styled(Body2)`
  color: ${({ theme }) => theme.white};
  width: 120px;
`;

interface InputProps {
  error?: boolean;
  hasFocus?: boolean;
  withIcon?: boolean;
}

export const InputText = styled.TextInput<InputProps>`
  min-width: ${({ error, withIcon }) => {
    if (error || withIcon) {
      return 'calc(70% - 20px)';
    }
    return '70%';
  }};
  color: ${({ theme }) => theme.white};
  font-size: ${Platform.OS === 'web' ? '1.4rem' : '14px'};
  line-height: ${Platform.OS === 'web' ? '2.0rem' : '20px'};
  font-weight: 400;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_400Regular'};
  letter-spacing: ${Platform.OS === 'web' ? '-0.044rem' : '-0.44px'};
  color: ${({ theme }) => theme.white};

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.red};
    `}

  ${({ hasFocus }) =>
    hasFocus &&
    css`
      color: ${({ theme }) => theme.white};
    `}
`;
