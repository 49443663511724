import styled from 'styled-components/native';
import { H5 } from '@nucleus/Typography';
import { transparentize } from 'polished';

export const Container = styled.View`
  display: flex;
  flex-direction: row;
  padding: 24px;
  align-items: center;
  margin-top: 16px;
`;

export const Text = styled(H5)`
  color: ${({ theme }) => transparentize(0.3, theme.white)};
`;
