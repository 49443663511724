import styled from 'styled-components/native';
import { Button } from '@atoms/Button';
import { Dimensions } from 'react-native';

export const Container = styled.View`
  margin-top: 16px;
  width: 60%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  height: 90%;
`;

export const UserDataContainer = styled.View`
  width: 100%;
  flex-direction: column;
  overflow-y: scroll;
  height: 40%;
  padding: 16px;
  border-width: 2px;
  border-radius: 8px;
  border-color: ${({ theme }) => theme.purpleBlue};
`;

export const RoleDataContainer = styled.View`
  width: 100%;
  flex-direction: column;
  overflow-y: scroll;
  height: 30%;
  padding: 16px;
  border-width: 2px;
  border-radius: 8px;
  margin-top: 32px;
  border-color: ${({ theme }) => theme.purpleBlue};
`;

export const FilterContainer = styled.View`
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 168px);
`;

export const Stack = styled.View`
  height: 32px;
  width: 100%;
`;

export const ClearButton = styled(Button)`
  height: ${() => {
    const { width } = Dimensions.get('window');
    if (width < 1300) {
      return '40px';
    }
    return '48px';
  }};
`;
