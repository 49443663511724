import { useRef } from 'react';
import { TextInputProps } from 'react-native';
import { useTheme } from 'styled-components/native';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { InputText } from './styles';

export interface RenameInputProps extends Omit<TextInputProps, 'onBlur'> {
  onBlur: () => void;
}

export const RenameInput = ({
  style,
  value,
  onBlur,
  ...rest
}: RenameInputProps) => {
  const inputRef = useRef(null);
  const theme = useTheme();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleKeyPress(event: any) {
    if (event.code === 'Enter') {
      onBlur();
    }
  }

  useOutsideClick({
    componentRef: inputRef,
    yOffset: 0,
    onOutsideClick: onBlur,
  });

  return (
    <InputText
      autoFocus
      ref={inputRef}
      style={style}
      placeholderTextColor={theme.purpleBlue}
      value={value}
      onKeyPress={handleKeyPress}
      {...rest}
    />
  );
};
