import styled from 'styled-components/native';
import { IconButton } from '@atoms/IconButton';

export const Container = styled.View`
  display: flex;
  width: 100%;
  height: calc(100vh - 64px);
`;

export const AddButton = styled(IconButton)`
  position: absolute;
  right: 72px;
  bottom: 56px;
  background-color: ${({ theme }) => theme.purpleBlue};
  width: 72px;
  height: 72px;
  border-radius: 36px;
  align-items: center;
  justify-content: center;
`;
