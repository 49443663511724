import { useTranslation } from 'react-i18next';
import { ActivityIndicator, StyleProp, ViewStyle } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Hoverable } from 'react-native-web-hooks';
import { Container, ContainerButton, Text } from './styles';

interface OpenCloseBadgeProps {
  closed: boolean;
  onOpenPress?: () => void;
  onClosePress?: () => void;
  loading?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const OpenCloseButton = ({
  closed,
  onOpenPress,
  onClosePress,
  loading = false,
  style,
}: OpenCloseBadgeProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return onOpenPress && onClosePress ? (
    <Hoverable>
      {isHovered => (
        <ContainerButton
          isHovered={isHovered}
          closed={closed}
          onPress={closed ? onOpenPress : onClosePress}
          style={style}
        >
          {loading ? (
            <ActivityIndicator
              size={8}
              color={closed ? theme.red : theme.eletricGreen}
            />
          ) : (
            <Text closed={closed}>{closed ? t('closed') : t('opened')}</Text>
          )}
        </ContainerButton>
      )}
    </Hoverable>
  ) : (
    <Container closed={closed} style={style}>
      <Text closed={closed}>{closed ? t('closed') : t('opened')}</Text>
    </Container>
  );
};
