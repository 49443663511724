import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 4px;
`;

export const WidgetsContainer = styled.View`
  width: 100%;
  height: calc(100% - 44px);
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.purpleBlack};
  margin-top: 4px;
`;
