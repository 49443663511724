import { useQuery } from 'react-query';
import { api } from '../userManagementApi';

export interface PublicUser {
  id: string;
  name: string;
}

export async function getPublicUser(user_id: string): Promise<PublicUser> {
  const getPublicUserResponse = await api.get(`/v1/public/users/${user_id}`);
  return getPublicUserResponse.data;
}

export function useQueryPublicUser(user_id: string) {
  return useQuery(['publicUser', user_id], () => getPublicUser(user_id));
}
