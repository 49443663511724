import { CSSProperties, useState, useEffect } from 'react';
import { XYCoord, useDragLayer } from 'react-dnd';

import WidgetDragPreview from '@atoms/WidgetDragPreview';

import { useWidgetGridContext } from '@contexts/WidgetGridContext';

import { gridConstants, ItemTypes } from '../../../hooks/useGridHelper';

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate3d(${x}px, ${y}px, 0)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export const CustomDragLayer = () => {
  const { isColliding, gridSizeData } = useWidgetGridContext();

  const [isCollidingBool, setIsCollidingBool] = useState(false);

  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer(monitor => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));

  useEffect(() => {
    if (currentOffset && gridSizeData)
      setIsCollidingBool(
        isColliding({
          ...item,
          left: currentOffset.x - gridConstants.GRID_PADDING,
          top:
            currentOffset.y +
            gridSizeData.scrollTop -
            gridSizeData.offsetTop -
            gridConstants.GRID_PADDING,
        }),
      );
  }, [gridSizeData, currentOffset, isColliding, item]);

  function renderItem() {
    switch (itemType) {
      case ItemTypes.WIDGET:
        return <WidgetDragPreview isColliding={isCollidingBool} {...item} />;
      default:
        return null;
    }
  }

  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        {renderItem()}
      </div>
    </div>
  );
};
