/* eslint-disable @typescript-eslint/no-empty-function */
import { useMemo } from 'react';
import { IWidget, useWidgetGridContext } from '@contexts/WidgetGridContext';
import { WidgetSidePanel } from '@molecules/WidgetSidePanel';
import { useMutationUpdateWidgets } from '@services/hooks/useMutationUpdateWidgets';
import { InputTag } from '@atoms/InputTag';
import {
  FakeEventWidgetData,
  fakeEventWidgetInitialData,
} from '@templates/FakeEventWidget/types';
import { FakeEventWidgetConfigData } from '@templates/FakeEventConfig/types';
import { DatePicker } from '@atoms/DatePicker';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { Select } from '@atoms/Select';
import { CategoriesSelector } from '@atoms/CategoriesSelector';
import { AddressFilterInput, Location } from '@molecules/AddressFilterInput';
import { categories } from '../../../../utils/categories';
import { Container, FilterContainer, Stack, ClearButton } from './styles';

import searchIcon from '../../../../assets/icons/search.svg';

interface WidgetExampleSideBarProps {
  widgetData: IWidget<FakeEventWidgetData, FakeEventWidgetConfigData>;
}

export interface FilterFormData {
  categories: number[];
  relevancies: number[];
  location?: Location;
  start_date?: Date;
  end_date?: Date;
  any_terms: string[];
}

const relevances = [
  { value: '1', label: 'low' },
  { value: '2', label: 'medium' },
  { value: '3', label: 'high' },
];

export const FakeEventWidgetSideBar = ({
  widgetData,
}: WidgetExampleSideBarProps) => {
  const { t } = useTranslation();
  const { setSidePanelData, updateWidgetData, widgets } =
    useWidgetGridContext();
  const mutationUpdateWidgets = useMutationUpdateWidgets();
  const widget:
    | IWidget<FakeEventWidgetData, FakeEventWidgetConfigData>
    | undefined = useMemo(
    () => widgets.find(widgetItem => widgetItem.id === widgetData.id),
    [widgets, widgetData.id],
  );

  const { control, handleSubmit, setValue } = useForm<FilterFormData>();

  const onSubmitFilter = async (data: FilterFormData) => {
    if (!widget) return;

    const updatedFilter = {
      ...widget.data.filters,
      categories: data.categories,
      relevancies: data.relevancies,
      location: data.location,
      start_date: data.start_date,
      end_date: data.end_date,
      any_terms: data.any_terms,
    };

    await mutationUpdateWidgets.mutateAsync([
      {
        id: widgetData.id,
        data: { filters: updatedFilter },
      },
    ]);

    updateWidgetData<FakeEventWidgetData>(widgetData.id, {
      data: { filters: updatedFilter },
    });
  };

  const clearFilters = async () => {
    setValue('categories', fakeEventWidgetInitialData.data.filters.categories);
    setValue(
      'relevancies',
      fakeEventWidgetInitialData.data.filters.relevancies,
    );
    setValue('location', fakeEventWidgetInitialData.data.filters.location);
    setValue('start_date', fakeEventWidgetInitialData.data.filters.start_date);
    setValue('end_date', fakeEventWidgetInitialData.data.filters.end_date);
    setValue('any_terms', fakeEventWidgetInitialData.data.filters.any_terms);

    await mutationUpdateWidgets.mutateAsync([
      {
        id: widgetData.id,
        data: { filters: fakeEventWidgetInitialData.data.filters },
      },
    ]);

    updateWidgetData(widgetData.id, {
      data: { filters: fakeEventWidgetInitialData.data.filters },
    });
  };

  return (
    <WidgetSidePanel
      title="Event Filters"
      onClose={() => setSidePanelData({ widget: undefined, isOpened: false })}
    >
      {widget && (
        <Container>
          <FilterContainer>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <CategoriesSelector
                  listOfCategories={categories}
                  selectedSubcategories={value}
                  onChange={onChange}
                  onBlur={handleSubmit(onSubmitFilter)}
                />
              )}
              name="categories"
              defaultValue={widget.data.filters.categories}
            />
            <Stack />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputTag
                  tags={value || []}
                  placeholder={t('any_term')}
                  icon={searchIcon}
                  onBlur={handleSubmit(onSubmitFilter)}
                  onChange={onChange}
                />
              )}
              name="any_terms"
              defaultValue={widget.data.filters.any_terms}
            />
            <Stack />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <AddressFilterInput
                  placeholder={t('type_address')}
                  val={value}
                  icon={searchIcon}
                  onSelectAddress={onChange}
                  onBlur={handleSubmit(onSubmitFilter)}
                />
              )}
              name="location"
              defaultValue={widget.data.filters.location}
            />

            <Stack />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  isMulti
                  selectedOptions={value.map((val: number) => String(val))}
                  placeholder={t('relevance')}
                  options={relevances}
                  onBlur={handleSubmit(onSubmitFilter)}
                  onChange={relevance =>
                    onChange(relevance.map(val => Number(val)))
                  }
                />
              )}
              name="relevancies"
              defaultValue={widget.data.filters.relevancies}
            />
            <Stack />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  placeholder={t('start_date')}
                  date={value}
                  onBlur={handleSubmit(onSubmitFilter)}
                  onChange={date => onChange(date)}
                />
              )}
              name="start_date"
              defaultValue={
                widget.data.filters.start_date
                  ? new Date(widget.data.filters.start_date)
                  : undefined
              }
            />
            <Stack />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  date={value}
                  placeholder={t('end_date')}
                  onBlur={handleSubmit(onSubmitFilter)}
                  onChange={date => onChange(date)}
                />
              )}
              name="end_date"
              defaultValue={
                widget.data.filters.end_date
                  ? new Date(widget.data.filters.end_date)
                  : undefined
              }
            />
          </FilterContainer>
          <Stack />
          <ClearButton onPress={clearFilters}>{t('clear_filters')}</ClearButton>
        </Container>
      )}
    </WidgetSidePanel>
  );
};
