import { Hoverable } from 'react-native-web-hooks';
import { useTheme } from 'styled-components/native';
import { darken } from 'polished';
import { useTranslation } from 'react-i18next';

import { H4 } from '@nucleus/Typography';

import plusIcon from '../../../../assets/icons/plus.svg';

import { Container, AddButton } from './styles';

interface WorkspacePageAddNewWidgetButtonProps {
  onAddPress: () => void;
}

export const WorkspacePageAddNewWidgetButton = ({
  onAddPress,
}: WorkspacePageAddNewWidgetButtonProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Container>
      <Hoverable>
        {isHovered => (
          <AddButton
            isHovered={isHovered}
            icon={plusIcon}
            width={36}
            height={36}
            viewBox="0 0 24 24"
            onPress={onAddPress}
            color={isHovered ? darken(0.1, theme.purpleBlue) : theme.purpleBlue}
          />
        )}
      </Hoverable>
      <H4 style={{ color: theme.purpleBlue }}>{t('add_new_widget')}</H4>
    </Container>
  );
};
