import React from 'react';
import { MapWidget } from '@templates/MapWidget';
import { CustomEventWidget } from '@templates/CustomEventWidget';
import { MapWidgetSideBar } from '@organisms/MapWidget/SideBar';
import { CustomEventWidgetSideBar } from '@organisms/CustomEventWidget/SideBar';
import { FakeEventWidget } from '@templates/FakeEventWidget';
import { FakeEventWidgetSideBar } from '@organisms/FakeEventWidget/SideBar';
import { IWidget } from '@contexts/WidgetGridContext';

// types slugs "custom-event-list" "event-list" "map-widget"

interface WidgetComponentProps {
  widgetData: IWidget;
}

interface WidgetComponents {
  [key: string]: ({ widgetData }: WidgetComponentProps) => JSX.Element;
}

interface WidgetInfo {
  typeSlug: string;
  widgetData: IWidget;
}

const widgetComponents: WidgetComponents = {
  'map-widget': MapWidget,
  'custom-event-list': CustomEventWidget,
  'event-list': FakeEventWidget,
};

const widgetSidePanelComponents: WidgetComponents = {
  'map-widget': MapWidgetSideBar,
  'custom-event-list': CustomEventWidgetSideBar,
  'event-list': FakeEventWidgetSideBar,
};

export const useWidgetComponentSelector = () => {
  const getWidgetComponent = ({ typeSlug, widgetData }: WidgetInfo) => {
    const Component = widgetComponents[typeSlug];
    if (!Component) return null;
    return Component && React.createElement(Component, { widgetData }, null);
  };

  const getWidgetSidePanelComponent = ({
    typeSlug,
    widgetData,
  }: WidgetInfo) => {
    const Component = widgetSidePanelComponents[typeSlug];
    if (!Component) return null;
    return React.createElement(Component, { widgetData }, null);
  };

  return {
    getWidgetComponent,
    getWidgetSidePanelComponent,
  };
};
