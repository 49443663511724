import { useQuery } from 'react-query';
import { api } from '../userManagementApi';

export interface WidgetConfig {
  id: string;
  name: string;
  type_id: string;
  user_id: string;
  type: {
    id: string;
    name: string;
    slug: string;
    description: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: any;
}

export interface GetWigetConfigsParams {
  widget_config_id: string;
}

export async function getWigetConfig({
  widget_config_id,
}: GetWigetConfigsParams): Promise<WidgetConfig> {
  const getWorkspaceResponse = await api.get(
    `/v1/widget/configs/${widget_config_id}`,
  );
  return getWorkspaceResponse.data;
}

export function useQueryWidgetConfig({
  widget_config_id,
}: GetWigetConfigsParams) {
  return useQuery(['widgetConfig', widget_config_id], () =>
    getWigetConfig({ widget_config_id }),
  );
}
