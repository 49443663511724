import { useWidgetGridContext } from '@contexts/WidgetGridContext';
import { useMutationCreateWidget } from '@services/hooks/useMutationCreateWidget';
import { MapData, mapWidgetinitialData } from '@templates/MapWidget';
import { useNavigation } from '@react-navigation/native';
import {
  FakeEventWidgetConfigData,
  fakeEventWidgetInitialConfigData,
} from '@templates/FakeEventConfig/types';
import {
  FakeEventWidgetData,
  fakeEventWidgetInitialData,
} from '@templates/FakeEventWidget/types';
import {
  CustomEventWidgetConfigData,
  customEventWidgetInitialConfigData,
} from '@templates/CustomEventConfig/types';
import {
  CustomEventWidgetData,
  customEventWidgetInitialData,
} from '@templates/CustomEventWidget/types';

import { useAuthContext } from '@contexts/AuthContext';

type ConfigTypes = CustomEventWidgetConfigData | FakeEventWidgetConfigData;

interface WidgetInitialConfig {
  name: string;
  config: ConfigTypes;
}

interface InitialConfig extends WidgetInitialConfig {
  type_id: string;
}

type DataTypes = MapData | CustomEventWidgetData | FakeEventWidgetData;

interface WidgetInitialData {
  grid_height: number;
  grid_width: number;
  data: DataTypes;
}

interface WidgetsInitialData {
  [key: string]: WidgetInitialData;
}

interface WidgetsInitialConfig {
  [key: string]: WidgetInitialConfig;
}

const widgetsInitialData: WidgetsInitialData = {
  'map-widget': mapWidgetinitialData,
  'event-list': fakeEventWidgetInitialData,
  'custom-event-list': customEventWidgetInitialData,
};

const initialConfigs: WidgetsInitialConfig = {
  'custom-event-list': customEventWidgetInitialConfigData,
  'event-list': fakeEventWidgetInitialConfigData,
};

interface CreateWidgetBaseParams {
  name: string;
  page_id: string;
  type_id: string;
  type_slug: string;
  config_id?: string;
  is_configurable: boolean;
}

export const useCreateNewWidget = (pageId: string) => {
  const { navigate } = useNavigation();
  const { createNewWidget } = useWidgetGridContext();
  const mutationCreateWidget = useMutationCreateWidget({ page_id: pageId });
  const { user } = useAuthContext();

  const createMapWidget = async ({
    page_id,
    type_id,
    config_id,
    type_slug,
    name,
    is_configurable,
  }: CreateWidgetBaseParams) => {
    if (!user) throw new Error('to create new should be logged');
    const initialData = widgetsInitialData[type_slug];

    const configData = {
      config_id,
      config:
        is_configurable && !config_id
          ? {
              type_id,
              ...initialConfigs[type_slug],
            }
          : undefined,
    };

    const newWidget = createNewWidget<DataTypes, InitialConfig>({
      name,
      page_id,
      type_id,
      ...initialData,
      ...configData,
    });

    const newCreatedWidget = await mutationCreateWidget.mutateAsync(newWidget);
    if (is_configurable && !config_id && newCreatedWidget.config_id) {
      navigate('WidgetConfig', { id: newCreatedWidget.config_id });
    }
  };

  return { createMapWidget, isLoading: mutationCreateWidget.isLoading };
};
