import React, { useState, useEffect, ReactNode, useCallback } from 'react';

import { createContext, useContextSelector } from 'use-context-selector';
import bboxPolygon from '@turf/bbox-polygon';
import { v4 as uuid } from 'uuid';
import { Geometry as NebulaGeometry } from '@nebula.gl/edit-modes';
import booleanContains from '@turf/boolean-contains';
import { useAuthContext } from '@contexts/AuthContext';
import { useQueryZones } from '@services/hooks/useQueryZones';
import { subWeeks } from 'date-fns';
import { useTabsControlContext, defaultFilters } from './TabsControlContext';
import { Filters, getTabData, updateTabData } from '../utils/tabApi';
import { useZonesContext, Zone } from './ZonesContext';
import { categories as baseCategories } from '../utils/categories';

export type Geometry = NebulaGeometry;

export interface Category {
  id: number;
  name: string;
  icon?: string;
  color: string;
  description: string;
  parent_id: number;
}

export interface SearchParamsData {
  bodyParams: {
    coordinates?: number[][][];
    main_courante_ids?: string;
  };
  queryParams: {
    categories: string;
    relevancies: string;
    start_date: Date;
    end_date: Date;
    any_terms?: string;
    all_terms?: string;
    exclude_terms?: string;
    teams: string;
  };
}

interface FiltersContextData {
  filters: Filters;
  updateFilters(newFilters: Filters): void;
  filterScreenOpen: boolean;
  updateFilterScreenOpen: (value: boolean) => void;
  getFiltersInParams: (filters: Filters) => SearchParamsData;
}

const FiltersContext = createContext<FiltersContextData>(
  {} as FiltersContextData,
);

interface EventsProviderProps {
  children: ReactNode;
}

const FiltersContextProvider = ({ children }: EventsProviderProps) => {
  const { user } = useAuthContext();
  const { selectedTab } = useTabsControlContext();
  const [filters, setFilters] = useState<Filters>(defaultFilters);
  const [filterScreenOpen, setFilterScreenOpen] = useState(false);
  const { removeZones, addNewZone, deleteZoneById } = useZonesContext();

  const { data: zones } = useQueryZones();

  const updateFilterScreenOpen = useCallback(
    (opened: boolean) => {
      updateTabData(selectedTab, { filterScreenOpen: opened });
      setFilterScreenOpen(opened);
    },
    [selectedTab],
  );

  const getFiltersInParams = useCallback(
    (filtersToPass: Filters) => {
      const defaultGeometryLimit = user?.organization.setup?.limitGeometry;
      const team_id =
        user?.teams && user?.teams.length > 0 ? user.teams[0].id : undefined;
      const userDefaultCategories = user?.organization.setup?.categories;

      const defaultCategories = userDefaultCategories || baseCategories;

      const allCategories = defaultCategories.reduce((accum, curr) => {
        return [...accum, ...curr.subcategories];
      }, [] as Category[]);

      let zoneCoordinates;
      if (filtersToPass.zones.length > 0) {
        const selectedZone = zones?.find(
          zone => zone.id === filtersToPass.zones[0],
        );
        if (selectedZone) {
          if (defaultGeometryLimit) {
            if (booleanContains(defaultGeometryLimit, selectedZone.geometry)) {
              zoneCoordinates = selectedZone.geometry.coordinates;
            }
          } else {
            zoneCoordinates = selectedZone.geometry.coordinates;
          }
        }
      }

      let geometry;

      if (filtersToPass.location) {
        if (defaultGeometryLimit) {
          const toValidGeometry = filtersToPass.location?.bbox
            ? bboxPolygon(filtersToPass.location.bbox).geometry
            : filtersToPass.location?.geometry;
          if (booleanContains(defaultGeometryLimit, toValidGeometry)) {
            geometry = toValidGeometry;
          }
        } else {
          geometry = filtersToPass.location?.bbox
            ? bboxPolygon(filtersToPass.location.bbox).geometry
            : filtersToPass.location?.geometry;
        }
      }

      const coordinates =
        zoneCoordinates ||
        geometry?.coordinates ||
        defaultGeometryLimit?.coordinates;

      const main_courante_ids =
        filtersToPass.mainCourante.length > 0
          ? filtersToPass.mainCourante
          : undefined;

      const queryParams = {
        categories:
          filtersToPass.categories.length === 0 || !filtersToPass.categories
            ? allCategories.map((cat: Category) => cat.id).join(',')
            : filtersToPass.categories.join(','),
        relevancies:
          filtersToPass.relevancies.length === 0 || !filtersToPass.relevancies
            ? '1,2,3'
            : filtersToPass.relevancies.join(','),
        start_date: filtersToPass.start_date
          ? filtersToPass.start_date
          : subWeeks(new Date(), 1),
        end_date: filtersToPass.end_date ? filtersToPass.end_date : new Date(),
        any_terms:
          filtersToPass.any_terms && filtersToPass.any_terms.length > 0
            ? filtersToPass.any_terms.join(',')
            : undefined,
        all_terms:
          filtersToPass.all_terms && filtersToPass.all_terms.length > 0
            ? filtersToPass.all_terms.join(',')
            : undefined,
        exclude_terms:
          filtersToPass.exclude_terms && filtersToPass.exclude_terms.length > 0
            ? filtersToPass.exclude_terms.join(',')
            : undefined,
        teams: team_id,
      };

      return {
        bodyParams: {
          coordinates,
          main_courante_ids,
        },
        queryParams,
      } as SearchParamsData;
    },
    [user, zones],
  );

  const updateFilters = useCallback(
    async (newFilters: Filters) => {
      updateTabData(selectedTab, { filters: newFilters });
      setFilters(newFilters);
    },
    [selectedTab],
  );

  useEffect(() => {
    async function loadData() {
      const tab = await getTabData(selectedTab);
      if (!tab) return;
      setFilterScreenOpen(tab.filterScreenOpen);
      setFilters(tab.filters);
    }
    loadData();
  }, [selectedTab]);

  useEffect(() => {
    if (filters.zones.length > 0) return;
    if (!filters.location) {
      removeZones('filter');
      return;
    }
    if (filters.location) {
      const geometry = filters.location.bbox
        ? bboxPolygon(filters.location.bbox).geometry
        : filters.location.geometry;

      const zone = {
        id: uuid(),
        type: 'Feature' as const,
        properties: {
          name: 'filter_zone',
          type: 'filter',
          color: '#FF2D2E',
          editable: false,
        },
        geometry,
      } as Zone;

      addNewZone(zone);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.location, filters.zones]);

  useEffect(() => {
    if (filters.zones.length === 0) {
      const zonesToRemove = zones
        ?.filter(zone => zone.id !== filters.zones[0])
        .map(zone => zone.id);

      zonesToRemove?.map(zoneId => zoneId && deleteZoneById(String(zoneId)));
      return;
    }
    if (filters.zones.length > 0) {
      const selectedZone = zones?.find(zone => zone.id === filters.zones[0]);
      const zonesToRemove = zones
        ?.filter(zone => zone.id !== filters.zones[0])
        .map(zone => zone.id);
      if (!selectedZone) return;
      removeZones('filter');
      zonesToRemove?.map(zoneId => zoneId && deleteZoneById(String(zoneId)));
      addNewZone(selectedZone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.zones, zones]);

  return (
    <FiltersContext.Provider
      value={{
        getFiltersInParams,
        filterScreenOpen,
        updateFilterScreenOpen,
        filters,
        updateFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

function useFilterScreenContext() {
  const filterScreenOpen = useContextSelector(
    FiltersContext,
    filtersContext => filtersContext.filterScreenOpen,
  );
  const updateFilterScreenOpen = useContextSelector(
    FiltersContext,
    filtersContext => filtersContext.updateFilterScreenOpen,
  );

  return {
    filterScreenOpen,
    updateFilterScreenOpen,
  };
}

function useFiltersContext() {
  const filters = useContextSelector(
    FiltersContext,
    filtersContext => filtersContext.filters,
  );
  const updateFilters = useContextSelector(
    FiltersContext,
    filtersContext => filtersContext.updateFilters,
  );
  const getFiltersInParams = useContextSelector(
    FiltersContext,
    filtersContext => filtersContext.getFiltersInParams,
  );

  return { filters, updateFilters, getFiltersInParams };
}

export { FiltersContextProvider, useFiltersContext, useFilterScreenContext };
