import { createStackNavigator } from '@react-navigation/stack';
import { useTheme } from 'styled-components/native';
import Workspaces from '../screens/Workspaces';
import Workspace from '../screens/Workspace';
import { Profile } from '../screens/Profile';
import { WidgetConfig } from '../screens/WidgetConfig';

type AppStackParamList = {
  Profile: undefined;
  Dashboards: undefined;
  Dashboard: { id: string };
  WidgetConfig: { id: string };
};

const AppStack = createStackNavigator<AppStackParamList>();

function WorkspaceNavigator() {
  const theme = useTheme();

  return (
    <AppStack.Navigator initialRouteName="Dashboards">
      <AppStack.Screen
        name="Dashboards"
        component={Workspaces}
        options={{
          headerShown: false,
          cardStyle: { backgroundColor: theme.background },
        }}
      />
      <AppStack.Screen
        name="Dashboard"
        component={Workspace}
        options={{
          headerShown: false,
          cardStyle: { backgroundColor: theme.background },
        }}
      />
      <AppStack.Screen
        name="WidgetConfig"
        component={WidgetConfig}
        options={{
          headerShown: false,
          cardStyle: { backgroundColor: theme.background },
        }}
      />
      <AppStack.Screen
        name="Profile"
        component={Profile}
        options={{
          headerShown: false,
          cardStyle: { backgroundColor: theme.background },
        }}
      />
    </AppStack.Navigator>
  );
}

export default WorkspaceNavigator;
