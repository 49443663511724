/* eslint-disable @typescript-eslint/no-empty-function */
import { useRef } from 'react';
import { Widget } from '@organisms/Widget';
import { IWidget } from '@contexts/WidgetGridContext';
import { CustomEventWidgetConfigData } from '@templates/CustomEventConfig/types';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { useFiltersContext } from '@contexts/FiltersContext';
import {
  Event,
  useQueryFiltredEvents,
} from '@services/hooks/useQueryFiltredEvents';

import ReactResizeDetector from 'react-resize-detector';

import { EventCard } from '@organisms/EventCard';

import { FakeEventWidgetData } from './types';
import { EventsContainer } from './styles.web';

export interface Size {
  width: number;
  height: number;
}

interface RowProps {
  events: Event[];
  activatedEventId: string | null | undefined;
  updateActivatedEventId: (eventId: string) => void;
}

export const Row = ({
  data,
  index,
  style,
}: ListChildComponentProps<RowProps>) => {
  const { events, activatedEventId, updateActivatedEventId } = data;
  const event = events[index];

  return (
    <div key={String(event.id)} style={style}>
      <EventCard
        isActive={activatedEventId === String(event.id)}
        event={event}
        onPress={() => updateActivatedEventId(String(event.id))}
      />
    </div>
  );
};

interface CustomEventWidgetProps {
  widgetData: IWidget<FakeEventWidgetData, CustomEventWidgetConfigData>;
}

export const FakeEventWidget = ({ widgetData }: CustomEventWidgetProps) => {
  const listRef = useRef<FixedSizeList>(null);

  const updateActivatedEventId = () => {};
  const { getFiltersInParams } = useFiltersContext();

  const { data: events } = useQueryFiltredEvents(
    widgetData.id,
    widgetData.data.filters,
    getFiltersInParams,
  );

  return (
    <Widget widgetData={widgetData}>
      {!!events && (
        <ReactResizeDetector>
          {({ width, height }: Size) => (
            <EventsContainer>
              {width && height && events.length > 0 ? (
                <FixedSizeList
                  ref={listRef}
                  height={height}
                  itemCount={events.length}
                  itemSize={200}
                  width={width >= 560 ? 560 : width}
                  itemData={{
                    events,
                    activatedEventId: null,
                    updateActivatedEventId,
                  }}
                >
                  {Row}
                </FixedSizeList>
              ) : null}
            </EventsContainer>
          )}
        </ReactResizeDetector>
      )}
    </Widget>
  );
};
