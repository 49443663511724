import { H6 } from '@nucleus/Typography';
import {
  Container,
  CircleLegend,
  StepCircleContainer,
  StepCircle,
  SpaceLine,
} from './styles';

interface ConfigStepsViewProps {
  stepIndex: number;
  steps: string[];
}

export const ConfigStepsView = ({ stepIndex, steps }: ConfigStepsViewProps) => {
  return (
    <Container>
      {steps.map((stepName, index) => (
        <StepCircleContainer>
          <StepCircle selected={stepIndex === index + 1}>
            <H6>{index + 1}</H6>
          </StepCircle>
          {index + 1 !== steps.length && <SpaceLine />}
          <CircleLegend>{stepName}</CircleLegend>
        </StepCircleContainer>
      ))}
    </Container>
  );
};
