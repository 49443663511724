import React, { useRef, useEffect, useState } from 'react';
import { useHover } from 'react-native-web-hooks';
import { Body1, Body2 } from '@nucleus/Typography';
import { MainCouranteCategory } from '@molecules/MainCouranteCardCategory';
import { MainCouranteCardHeader } from '@molecules/MainCouranteCardHeader';
import { MainCouranteCardCityAndTime } from '@molecules/MainCouranteCardCityAndTime';
import { MainCouranteCardActor } from '@molecules/MainCouranteCardActor';
import { MainCouranteCardActionTypeList } from '@molecules/MainCouranteCardActionTypeList';
import { MainCourante } from '@services/hooks/useQueryFiltredMainCourantes';
import { api } from '@services/userManagementApi';

import { Container, Overlay, Content, Footer } from './styles';

interface EventCardProps {
  isActive?: boolean;
  onPress: () => void;
  mainCourante: MainCourante;
}

const areEqual = (prevProps: EventCardProps, nextProps: EventCardProps) => {
  if (prevProps.isActive !== nextProps.isActive) {
    return false;
  }

  if (prevProps.mainCourante !== nextProps.mainCourante) {
    return false;
  }

  return true;
};

export const MainCouranteCard = React.memo(
  ({ isActive, mainCourante, onPress }: EventCardProps) => {
    const [actorAvatar, setActorAvatar] = useState<string>();
    const [actorName, setActorName] = useState<string>();
    const ref = useRef(null);
    const isHovered = useHover(ref);

    useEffect(() => {
      async function getActorInfo(id: string) {
        try {
          const response = await api(`/v1/public/users/${id}`);
          if (response.data.avatar) {
            setActorAvatar(response.data.avatar);
            return;
          }
          if (response.data.name) {
            setActorName(response.data.name);
            return;
          }
        } catch {
          setActorAvatar(undefined);
          setActorName(undefined);
        }
      }
      if (mainCourante.user_id) {
        getActorInfo(mainCourante.user_id);
      }
    }, [mainCourante]);

    return (
      <Container ref={ref} isHovered={isHovered} onPress={onPress}>
        {isActive && <Overlay />}
        <MainCouranteCategory />
        <Content>
          <MainCouranteCardHeader
            intensity={mainCourante.priority}
            closed={mainCourante.closed}
          />

          {mainCourante.name.length < 110 ? (
            <Body1 numberOfLines={2}>{mainCourante.name}</Body1>
          ) : (
            <Body2 numberOfLines={2}>{mainCourante.name}</Body2>
          )}

          <MainCouranteCardCityAndTime
            locationName={mainCourante.location_name}
            date={mainCourante.formatted_date}
          />

          {actorAvatar ||
            (actorName && (
              <MainCouranteCardActor
                avatar={actorAvatar}
                fallbackName={actorName}
              />
            ))}

          <MainCouranteCardActionTypeList
            total={mainCourante.main_courante_actions_total}
            opened={mainCourante.main_courante_actions_opened}
            closed={mainCourante.main_courante_actions_closed}
          />

          <Footer />
        </Content>
      </Container>
    );
  },
  areEqual,
);
