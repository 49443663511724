import React from 'react';
import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';
import { Select, Option } from '@atoms/Select';
import { CategoriesSelector, Category } from '@atoms/CategoriesSelector';

import { AddressFilterInput, Location } from '@molecules/AddressFilterInput';
import { Container, Stack } from './styles';

import searchIcon from '../../../assets/icons/search.svg';

export interface BasicFilterFormData {
  categories: number[];
  relevancies: number[];
  location?: Location;
  mainCourante: string[];
}

interface EventsFilterBasicFiltersProps {
  control: Control<BasicFilterFormData>;
  filters: BasicFilterFormData;
  onBlurFields: () => void;
  relevancesOptions: Option[];
  mainCourantesOptions: Option[];
  listOfCategories: Category[];
}

export const EventsFilterBasicFilters = ({
  control,
  filters,
  onBlurFields,
  relevancesOptions,
  mainCourantesOptions,
  listOfCategories,
}: EventsFilterBasicFiltersProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <CategoriesSelector
            listOfCategories={listOfCategories}
            selectedSubcategories={value}
            onChange={onChange}
            onBlur={onBlurFields}
          />
        )}
        name="categories"
        defaultValue={filters.categories}
      />
      <Stack />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <AddressFilterInput
            placeholder={t('type_address')}
            val={value}
            icon={searchIcon}
            onSelectAddress={onChange}
            onBlur={onBlurFields}
          />
        )}
        name="location"
        defaultValue={filters.location}
      />

      <Stack />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            isMulti
            selectedOptions={value.map((val: number) => String(val))}
            placeholder={t('relevance')}
            options={relevancesOptions}
            onBlur={onBlurFields}
            onChange={relevance => onChange(relevance.map(val => Number(val)))}
          />
        )}
        name="relevancies"
        defaultValue={filters.relevancies}
      />
      <Stack />

      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            isMulti
            selectedOptions={value}
            style={{ zIndex: 1 }}
            placeholder={t('main_courante')}
            options={mainCourantesOptions}
            onBlur={onBlurFields}
            onChange={onChange}
          />
        )}
        name="mainCourante"
        defaultValue={filters.mainCourante}
      />
    </Container>
  );
};
