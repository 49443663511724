import { Modal } from 'react-native';
import { Spinner } from '@atoms/Spinner';
import { Container } from './styles';

interface LoadingModalProps {
  visible?: boolean;
}

export const LoadingModal = ({ visible }: LoadingModalProps) => {
  return (
    <Modal visible={visible} transparent>
      <Container>
        <Spinner size="large" />
      </Container>
    </Modal>
  );
};
