import { ResizeHandle } from 'react-resizable';

export const gridConstants = {
  GRID_CELL_COUNT: 16,
  GRID_PADDING: 8,
  GRID_CELL_PADDING: 2,
  WIDGET_INNER_PADDING: 4,
  SIDE_PANEL_WIDTH: 0.25, // grid is 0.8 then, all width is 1.0
  SCROLLBAR_WIDTH: 4,
};

export const ItemTypes = {
  WIDGET: 'widget',
};

export const useGridHelper = () => {
  const {
    GRID_CELL_COUNT,
    GRID_PADDING,
    WIDGET_INNER_PADDING,
    SIDE_PANEL_WIDTH,
    GRID_CELL_PADDING,
    SCROLLBAR_WIDTH,
  } = gridConstants;

  const clamp = (number: number, min: number, max: number) => {
    return Math.max(min, Math.min(number, max));
  };

  const gridPosToPx = (
    gridPos: number,
    innerWidth: number,
    sidePanelIsOpen = false,
  ) => {
    return (
      (((gridPos * 100) / GRID_CELL_COUNT) *
        (innerWidth * (1 - (sidePanelIsOpen ? SIDE_PANEL_WIDTH : 0)) -
          (GRID_PADDING * 2 + GRID_CELL_PADDING / 2 + SCROLLBAR_WIDTH / 2))) /
      100
    );
  };

  // almost the same formula but this fn accounts for inner widget padding
  const gridSizeToPx = (
    gridSize: number,
    innerWidth: number,
    sidePanelIsOpen = false,
  ) =>
    (((gridSize * 100) / GRID_CELL_COUNT) *
      (innerWidth * (1 - (sidePanelIsOpen ? SIDE_PANEL_WIDTH : 0)) -
        (GRID_PADDING * 2 + GRID_CELL_PADDING / 2 + SCROLLBAR_WIDTH / 2))) /
      100 -
    WIDGET_INNER_PADDING * 2;

  const snapToGrid = (
    x: number,
    y: number,
    width: number,
    height: number,
    gridWidth: number,
  ) => {
    const gridCellSize =
      (gridWidth -
        (GRID_PADDING * 2 + GRID_CELL_PADDING / 2 + SCROLLBAR_WIDTH / 2)) /
      GRID_CELL_COUNT;

    const gridX = Math.round(x / gridCellSize);
    const gridY = Math.round(y / gridCellSize);
    let snappedX = gridX * gridCellSize;
    const snappedY = gridY * gridCellSize;

    snappedX = clamp(
      snappedX,
      0,
      gridCellSize * (GRID_CELL_COUNT - Math.round(width / gridCellSize)),
    );

    // snappedY = clamp(snappedY, 0, (GRID_CELL_COUNT - height) * gridCellSize);

    return [snappedX, gridX, snappedY, gridY];
  };
  const snapToGridSize = (
    x: number,
    y: number,
    width: number,
    height: number,
    gridContainerWidth: number,
  ) => {
    const gridCellSize =
      (gridContainerWidth -
        (GRID_PADDING * 2 + GRID_CELL_PADDING / 2 + SCROLLBAR_WIDTH / 2)) /
      GRID_CELL_COUNT;

    const gridX = Math.round(x / gridCellSize);
    // const gridY = Math.round(y / gridCellSize);

    const gridWidth = Math.min(
      GRID_CELL_COUNT - gridX,
      Math.round(width / gridCellSize),
    );

    const pxWidth = gridWidth * gridCellSize - WIDGET_INNER_PADDING * 2;

    const gridHeight = Math.round(height / gridCellSize);

    const pxHeight = gridHeight * gridCellSize - WIDGET_INNER_PADDING * 2;

    return { width: pxWidth, height: pxHeight, gridWidth, gridHeight };
  };

  interface CalculateNewPositionParams {
    movedWidth: number;
    movedHeight: number;
    actualLeft: number;
    actualTop: number;
    handle: ResizeHandle;
  }

  const calculateNewPosition = ({
    movedWidth,
    movedHeight,
    actualLeft,
    actualTop,
    handle,
  }: CalculateNewPositionParams) => {
    let newLeft = actualLeft;
    let newTop = actualTop;

    if (handle === 'w') {
      newLeft = actualLeft + movedWidth;
    }

    if (handle === 'n') {
      newTop = actualTop + movedHeight;
    }

    if (handle === 'sw') {
      newLeft = actualLeft + movedWidth;
    }

    if (handle === 'nw') {
      newLeft = actualLeft + movedWidth;
      newTop = actualTop + movedHeight;
    }

    if (handle === 'ne') {
      newTop = actualTop + movedHeight;
    }

    return { newLeft, newTop };
  };

  return {
    snapToGrid,
    snapToGridSize,
    gridPosToPx,
    gridSizeToPx,
    calculateNewPosition,
  };
};
