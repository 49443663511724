import React from 'react';
import { Platform, View } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useTheme } from 'styled-components/native';
import DashboardNavigation from './DashboardNavigation';
import Analytics from '../screens/Analytics';

import WorldIcon from '../assets/icons/dashboard.svg';
import ChartIcon from '../assets/icons/analytics.svg';

type AppStackParamList = {
  Dash: undefined;
  Analytics: undefined;
  Workspaces: undefined;
  Workspace: { id: string };
  WidgetConfig: { id: string };
};

const AppStack = createStackNavigator<AppStackParamList>();
const AppBottomTab = createBottomTabNavigator();

function ChallengeNavigator() {
  const theme = useTheme();

  if (Platform.OS === 'web') {
    return (
      <AppStack.Navigator initialRouteName="Dash">
        <AppStack.Screen
          name="Dash"
          component={DashboardNavigation}
          options={{
            headerShown: false,
            cardStyle: { backgroundColor: theme.background },
          }}
        />
        <AppStack.Screen
          name="Analytics"
          component={Analytics}
          options={{
            headerShown: false,
            cardStyle: { backgroundColor: theme.background },
          }}
        />
      </AppStack.Navigator>
    );
  }
  return (
    <View style={{ flex: 1 }}>
      <AppBottomTab.Navigator
        initialRouteName="Dash"
        sceneContainerStyle={{ backgroundColor: theme.background }}
        tabBarOptions={{
          showLabel: false,
          activeBackgroundColor: theme.purple,
          labelStyle: { color: theme.white, fontSize: 12 },
          style: {
            backgroundColor: theme.blueishBlack,
          },
        }}
      >
        <AppBottomTab.Screen
          name="Dash"
          component={DashboardNavigation}
          options={{
            tabBarIcon: () => (
              <WorldIcon
                width={20}
                height={20}
                viewBox="0 0 24 24"
                fill={theme.white}
              />
            ),
          }}
        />
        <AppBottomTab.Screen
          name="Analytics"
          component={Analytics}
          options={{
            tabBarIcon: () => (
              <ChartIcon
                width={20}
                height={20}
                viewBox="0 0 24 24"
                fill={theme.white}
              />
            ),
          }}
        />
      </AppBottomTab.Navigator>
    </View>
  );
}

export default ChallengeNavigator;
