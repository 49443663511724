import { useState } from 'react';
import { H5 } from '@nucleus/Typography';
import { ConfigStepsView } from '@atoms/ConfigStepsView';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@atoms/IconButton';
import { RenameInput } from '@atoms/RenameInput';
import { Container } from './styles';
import editIcon from '../../../../assets/icons/edit.svg';

interface CustomEventConfigHeaderProps {
  title?: string;
  onUpdateTitle?: (newTitle: string) => void;
  stepIndex?: number;
  steps?: string[];
}

export const CustomEventConfigHeader = ({
  title,
  onUpdateTitle,
  stepIndex,
  steps,
}: CustomEventConfigHeaderProps) => {
  const [editingNameMode, setEditingNameMode] = useState(false);
  const { t } = useTranslation();
  return (
    <Container>
      {editingNameMode ? (
        <RenameInput
          style={{ fontSize: 20, maxWidth: 200, marginRight: 16, height: 30 }}
          value={title}
          onBlur={() => setEditingNameMode(false)}
          onChangeText={onUpdateTitle}
        />
      ) : (
        <H5 style={{ marginRight: 16 }}>{title || t('custom_event_config')}</H5>
      )}

      <IconButton
        icon={editIcon}
        width={16}
        height={16}
        viewBox="0 0 12 12"
        onPress={() => setEditingNameMode(true)}
      />
      {stepIndex && steps && (
        <ConfigStepsView steps={steps} stepIndex={stepIndex} />
      )}
    </Container>
  );
};
