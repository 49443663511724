/* eslint-disable no-nested-ternary */
import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import ChallengeNavigator from './ChallengeNavigation';
import WorkspaceNavigator from './WorkspaceNavigation';
import AuthNavigator from './AuthNavigation';
import { useAuthContext } from '../contexts/AuthContext';

export default function Navigation() {
  const { user } = useAuthContext();

  const linking = {
    prefixes: ['https://localhost:3000', 'localhost://'],
    config: {
      screens: {
        SignIn: '/signIn',
        ForgetPassword: '/forgetpassword',
        ResetPassword: '/resetpassword',
        Dash: '/',
        Analytics: '/analytics',
        Dashboards: '/dashboards',
        Dashboard: '/dashboard/:id',
        WidgetConfig: '/widget/config/:id',
        Profile: '/profile',
      },
    },
  };
  return (
    <NavigationContainer linking={linking}>
      {user ? (
        user.roles.includes('workspaces.service') ? (
          <WorkspaceNavigator />
        ) : (
          <ChallengeNavigator />
        )
      ) : (
        <AuthNavigator />
      )}
    </NavigationContainer>
  );
}
