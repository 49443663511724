import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  ReactNode,
  SyntheticEvent,
} from 'react';
import { useDrag, DragSourceMonitor } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';

import { LoadingModal } from '@atoms/LoadingModal';

import { WidgetHeader } from '@molecules/WidgetHeader';
import { WidgetContent } from '@molecules/WidgetContent';
import { ConfirmCancelModal } from '@molecules/ConfirmCancelModal';

import { IWidget, useWidgetGridContext } from '@contexts/WidgetGridContext';

import { useMutationUpdateWidgets } from '@services/hooks/useMutationUpdateWidgets';
import { useMutationDeleteWidget } from '@services/hooks/useMutationDeleteWidget';

import { ResizeCallbackData } from 'react-resizable';
import {
  gridConstants,
  useGridHelper,
  ItemTypes,
} from '../../../hooks/useGridHelper';
import { Container } from './styles';

const { GRID_PADDING, GRID_CELL_PADDING, SCROLLBAR_WIDTH, GRID_CELL_COUNT } =
  gridConstants;

interface WidgetProps {
  widgetData: IWidget;
  children: ReactNode;
}

export const Widget = ({ widgetData, children }: WidgetProps) => {
  const mutationUpdateWidgets = useMutationUpdateWidgets();
  const { navigate } = useNavigation();
  const { t } = useTranslation();

  const { snapToGridSize, snapToGrid, calculateNewPosition } = useGridHelper();
  const {
    setWidgets,
    gridSizeData,
    isColliding,
    sidePanelData,
    updateWidgetData,
  } = useWidgetGridContext();

  const mutationDeleteWidget = useMutationDeleteWidget({
    page_id: widgetData.page_id,
  });

  const { id, left, top, width, height } = widgetData;

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const [{ actualWidth, actualHeight }, setActualSize] = useState({
    actualWidth: width,
    actualHeight: height,
  });

  const [{ actualLeft, actualTop }, setActualPosition] = useState({
    actualLeft: left,
    actualTop: top,
  });

  const [[minSizeX, minSizeY], setMinSize] = useState([0, 0]);

  const savedSizes = useRef({ width: 0, height: 0 });
  const savedPositions = useRef({ left: 0, top: 0 });

  // we're keeping a copy of what the current sizes are at the start of resize
  // so we can come back to it if the size when ending the resize creates a collision
  const handleResizeStart = useCallback(() => {
    savedSizes.current = { width: actualWidth, height: actualHeight };
    savedPositions.current = { left: actualLeft, top: actualTop };
  }, [actualWidth, actualHeight, actualLeft, actualTop]);

  const handleResize = useCallback(
    (_: SyntheticEvent, { size, handle }: ResizeCallbackData) => {
      const movedWidth = actualWidth - size.width;
      const movedHeight = actualHeight - size.height;

      const { newLeft, newTop } = calculateNewPosition({
        movedWidth,
        movedHeight,
        actualLeft,
        actualTop,
        handle,
      });

      setActualPosition({
        actualLeft: newLeft,
        actualTop: newTop,
      });

      setActualSize({
        actualWidth: size.width,
        actualHeight: size.height,
      });
    },
    [actualHeight, actualLeft, actualTop, actualWidth, calculateNewPosition],
  );

  const handleResizeStop = useCallback(
    (_: SyntheticEvent, { size, handle }: ResizeCallbackData) => {
      const movedWidth = actualWidth - size.width;
      const movedHeight = actualHeight - size.height;

      const { newLeft, newTop } = calculateNewPosition({
        movedWidth,
        movedHeight,
        actualLeft,
        actualTop,
        handle,
      });

      if (gridSizeData) {
        const snappedSizes = snapToGridSize(
          newLeft,
          newTop,
          size.width,
          size.height,
          gridSizeData.width,
        );

        const [snappedX, gridX, snappedY, gridY] = snapToGrid(
          newLeft,
          newTop,
          size.width,
          size.height,
          gridSizeData.width,
        );

        // we wanna check if the widget we're editing doesnt overlap another with the new size

        if (
          isColliding({
            ...widgetData,
            ...snappedSizes,
            left: snappedX,
            top: snappedY,
            grid_left: gridX,
            grid_top: gridY,
          })
        ) {
          setActualSize({
            actualWidth: savedSizes.current.width,
            actualHeight: savedSizes.current.height,
          });
          setActualPosition({
            actualLeft: savedPositions.current.left,
            actualTop: savedPositions.current.top,
          });
        } else {
          setActualSize({
            actualWidth: snappedSizes.width,
            actualHeight: snappedSizes.height,
          });

          setActualPosition({
            actualLeft: snappedX,
            actualTop: snappedY,
          });

          setWidgets((draft: IWidget[]) => {
            const widget = draft.find((w: IWidget) => w.id === id);
            if (widget) {
              widget.width = snappedSizes.width;
              widget.grid_width = snappedSizes.gridWidth;
              widget.height = snappedSizes.height;
              widget.grid_height = snappedSizes.gridHeight;
              widget.left = snappedX;
              widget.grid_left = gridX;
              widget.top = snappedY;
              widget.grid_top = gridY;
            }
          });
        }
      }
    },
    [
      actualWidth,
      actualHeight,
      calculateNewPosition,
      actualLeft,
      actualTop,
      gridSizeData,
      snapToGridSize,
      snapToGrid,
      isColliding,
      widgetData,
      setWidgets,
      id,
    ],
  );

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      // idk how to make it react to state yet
      canDrag: () => true,
      type: ItemTypes.WIDGET,
      item: { id, left, top, width, height },
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top, width, height, widgetData],
  );

  const handleRename = async (newName: string) => {
    if (newName === widgetData.name) return;
    await mutationUpdateWidgets.mutateAsync([
      {
        id: widgetData.id,
        name: newName,
      },
    ]);

    updateWidgetData(widgetData.id, {
      name: newName,
    });
  };

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  useEffect(() => {
    setActualSize(oldSize => ({
      ...oldSize,
      actualWidth: width,
      actualHeight: height,
    }));
    setActualPosition(oldPosition => ({
      ...oldPosition,
      actualLeft: left,
      actualTop: top,
    }));
  }, [width, height, left, top]);

  useEffect(() => {
    if (gridSizeData) {
      const gridCellSize =
        (gridSizeData.width -
          (GRID_PADDING * 2 + GRID_CELL_PADDING / 2 + SCROLLBAR_WIDTH / 2)) /
        GRID_CELL_COUNT;

      const minSnapped = snapToGridSize(
        left,
        top,
        gridCellSize * 2,
        gridCellSize * 2,
        gridSizeData.width,
      );

      setMinSize([minSnapped.width, minSnapped.height]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridSizeData, sidePanelData.isOpened]);

  return (
    <Container left={actualLeft} top={actualTop} isDragging={isDragging}>
      <WidgetContent
        isColliding={false}
        isDragPreview={false}
        dragRef={drag}
        height={actualHeight}
        width={actualWidth}
        onResizeStart={handleResizeStart}
        onResize={handleResize}
        onResizeStop={handleResizeStop}
        minConstraints={[minSizeX, minSizeY]}
      >
        <WidgetHeader
          onConfigure={
            widgetData.config_id
              ? () => navigate('WidgetConfig', { id: widgetData.config_id })
              : undefined
          }
          onRename={handleRename}
          onDelete={() => setConfirmDeleteModal(true)}
          widgetData={widgetData}
        />
        {children}
      </WidgetContent>
      {confirmDeleteModal && (
        <ConfirmCancelModal
          title={t('delete_widget_title')}
          isVisible={confirmDeleteModal}
          onCancel={() => setConfirmDeleteModal(false)}
          onConfirm={() => mutationDeleteWidget.mutateAsync(widgetData.id)}
        />
      )}
      {(mutationUpdateWidgets.isLoading || mutationDeleteWidget.isLoading) && (
        <LoadingModal
          visible={
            mutationUpdateWidgets.isLoading || mutationDeleteWidget.isLoading
          }
        />
      )}
    </Container>
  );
};
