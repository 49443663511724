import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.background};
  padding: 16px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const RightContainer = styled.div`
  display: flex;
  width: 35%;
  height: 100%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: calc(100vh - 65px);
  background: ${({ theme }) => theme.black};
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
