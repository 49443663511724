import { Hoverable } from 'react-native-web-hooks';
import { useTheme } from 'styled-components/native';
import { darken } from 'polished';

import plusIcon from '../../../../assets/icons/plus.svg';
import { Button } from './styles';

interface WorkspaceAreaAddButtonProps {
  onAddPress: () => void;
}

export const WorkspacesAreaAddButton = ({
  onAddPress,
}: WorkspaceAreaAddButtonProps) => {
  const theme = useTheme();
  return (
    <Hoverable>
      {isHovered => (
        <Button
          isHovered={isHovered}
          icon={plusIcon}
          width={36}
          height={36}
          viewBox="0 0 24 24"
          onPress={onAddPress}
          color={isHovered ? darken(0.1, theme.white) : theme.white}
        />
      )}
    </Hoverable>
  );
};
