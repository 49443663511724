import { TouchableOpacity } from 'react-native';
import { useState, useRef, ComponentType, CSSProperties } from 'react';
import { Icon } from '@nucleus/Icon';
import { useTranslation } from 'react-i18next';
import { Portal } from '@gorhom/portal';
import { SvgProps, NumberProp, Color } from 'react-native-svg';
import { usePopper } from 'react-popper';
import expandMoreIcon from '../../../assets/icons/expand_more.svg';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

import {
  Container,
  DropDownButton,
  DropDownMasterWraper,
  DropDownItem,
  DropDownItemText,
  WrapperArea,
} from './styles';

export interface Option {
  value: string;
  label: string;
  icon?: ComponentType<SvgProps>;
  iconWidth?: NumberProp;
  iconHeight?: NumberProp;
  iconViewBox?: string;
  iconFill?: Color;
  onPress: () => void;
}

interface SelectProps {
  options: Option[];
  style?: CSSProperties;
  icon?: ComponentType<SvgProps>;
  iconWidth?: NumberProp;
  iconHeight?: NumberProp;
  iconViewBox?: string;
  iconFill?: Color;
}

export const DropDown = ({
  options,
  style,
  icon,
  iconWidth,
  iconHeight,
  iconViewBox,
  iconFill,
}: SelectProps) => {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const wrapperRef = useRef<TouchableOpacity>(null);
  const dropButtonRef = useRef<TouchableOpacity>(null);

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });

  useOutsideClick({
    componentRef: wrapperRef,
    yOffset: 20,
    onOutsideClick: () => setIsOpened(false),
  });

  return (
    <Container style={style} ref={setReferenceElement}>
      <DropDownButton
        ref={dropButtonRef}
        onPress={() => setIsOpened(oldState => !oldState)}
      >
        <Icon
          as={icon || expandMoreIcon}
          height={iconHeight}
          width={iconWidth}
          viewBox={iconViewBox}
          fill={iconFill}
        />
      </DropDownButton>
      {isOpened && dropButtonRef.current && (
        <Portal>
          <DropDownMasterWraper
            parentElement={dropButtonRef.current}
            // ref={wrapperRef}
            ref={setPopperElement}
            style={{
              ...styles.popper,
            }}
            {...attributes.popper}
          >
            <WrapperArea ref={wrapperRef}>
              {options.map(option => (
                <DropDownItem
                  key={option.value}
                  onPress={() => {
                    setIsOpened(false);
                    option.onPress();
                  }}
                >
                  {option.icon && (
                    <Icon
                      as={option.icon}
                      height={option.iconHeight}
                      width={option.iconWidth}
                      viewBox={option.iconViewBox}
                      fill={option.iconFill}
                      style={{ marginRight: 8 }}
                    />
                  )}
                  <DropDownItemText>{t(option.label)}</DropDownItemText>
                </DropDownItem>
              ))}
            </WrapperArea>
          </DropDownMasterWraper>
        </Portal>
      )}
    </Container>
  );
};
