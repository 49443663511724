export const frCategories = [
  {
    id: 1,
    name: 'Phénomènes naturels',
    color: '#3396F1',
    subcategories: [
      {
        id: 4,
        name: 'Risque sismique',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        color: '#3396F1',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 132,
        created_at: null,
        updated_at: '2021-05-18T09:26:34.999Z',
        deleted_at: null,
      },
      {
        id: 6,
        name: 'Inondations',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/floods_icon.svg',
        color: '#3396F1',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 133,
        created_at: null,
        updated_at: '2021-05-19T09:54:55.693Z',
        deleted_at: null,
      },
      {
        id: 8,
        name: 'Feux de forêt',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/wildfires_icon.svg',
        color: '#B22222',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 134,
        created_at: null,
        updated_at: '2021-05-19T09:56:35.480Z',
        deleted_at: null,
      },
      {
        id: 7,
        name: 'Cyclones',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/cyclone_icon.svg',
        color: '#3396F1',
        description:
          'A tropical storm with winds of 74 miles (119 km) per hour or greater that can be several hundred miles/kilometres wide and is usually accompanied by rain, thunder, and lightning.',
        parent_id: 135,
        created_at: null,
        updated_at: '2021-06-18T15:36:17.860Z',
        deleted_at: null,
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
      },
      {
        id: 159,
        name: 'Tempêtes',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/storm_icon.svg',
        color: '#3396F1',
        description: '',
        parent_id: 135,
        created_at: '2021-05-19T09:59:33.265Z',
        updated_at: '2021-05-25T14:06:48.478Z',
        deleted_at: null,
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
      },
      {
        id: 155,
        name: 'Avalanche',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/avalanche_icon.svg',
        color: '#3396F1',
        description: '',
        parent_id: 133,
        created_at: '2021-05-19T09:54:36.309Z',
        updated_at: '2021-05-19T09:54:36.309Z',
        deleted_at: null,
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
      },
      {
        id: 227,
        name: 'Vagues subversives',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/tidal_waves_icon.svg',
        color: '#3396F1',
        description: '',
        parent_id: 135,
        created_at: '2021-05-25T14:06:38.797Z',
        updated_at: '2021-05-25T14:06:38.797Z',
        deleted_at: null,
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
      },
      {
        id: 250,
        name: 'Érosion côtière',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/coastal_flood_icon.svg',
        color: '#3396F1',
        description: '',
        parent_id: 6,
        created_at: '2021-06-22T09:20:33.372Z',
        updated_at: '2021-06-22T09:20:33.372Z',
        deleted_at: null,
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
      },
      {
        id: 251,
        name: 'Canicule',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/heatwave_icon.svg',
        color: '#8B0000',
        description: '',
        parent_id: 156,
        created_at: '2021-06-22T09:21:10.810Z',
        updated_at: '2021-06-22T09:21:10.810Z',
        deleted_at: null,
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
      },
      {
        id: 252,
        name: 'Grand froid',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/severe_cold_icon.svg',
        color: '#3396F1',
        description: '',
        parent_id: 156,
        created_at: '2021-06-22T09:21:22.570Z',
        updated_at: '2021-06-22T09:21:22.570Z',
        deleted_at: null,
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
      },
      {
        id: 253,
        name: 'Vent',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/wind_icon.svg',
        color: '#3396F1',
        description: '',
        parent_id: 135,
        created_at: '2021-06-22T09:22:17.286Z',
        updated_at: '2021-06-22T09:22:17.286Z',
        deleted_at: null,
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
      },
      {
        id: 254,
        name: 'Neige',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/snow_icon.svg',
        color: '#3396F1',
        description: '',
        parent_id: 135,
        created_at: '2021-06-22T09:22:27.046Z',
        updated_at: '2021-06-22T09:22:27.046Z',
        deleted_at: null,
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
      },
      {
        id: 256,
        name: 'Crue soudaine',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/rain_flood_icon.svg',
        color: '#3396F1',
        description: '',
        parent_id: 255,
        created_at: '2021-06-22T09:24:59.364Z',
        updated_at: '2021-06-22T09:24:59.364Z',
        deleted_at: null,
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
      },
      {
        id: 275,
        name: 'Glissement de terrains',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/mouvements.svg',
        color: '#3396F1',
        description: '',
        parent_id: 132,
        created_at: '2021-07-05T14:00:34.518Z',
        updated_at: '2021-07-05T14:00:34.518Z',
        deleted_at: null,
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
      },
    ],
  },
  {
    id: 263,
    name: 'Evènements routiers',
    color: '#8B4513',
    subcategories: [
      {
        id: 161,
        name: 'Accident de la route',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/roadway_accidents_icon.svg',
        color: '#8B4513',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 263,
        created_at: '2021-05-19T10:07:15.422Z',
        updated_at: '2021-06-29T10:29:12.641Z',
        deleted_at: null,
      },
      {
        id: 266,
        name: 'Col fermé',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/mountain_pass_closed_icon.svg',
        color: '#8B4513',
        description: '',
        parent_id: 263,
        created_at: '2021-06-29T10:33:03.868Z',
        updated_at: '2021-06-29T10:34:47.889Z',
        deleted_at: null,
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
      },
      {
        id: 264,
        name: 'Chantier routier',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/roadway_work_icon.svg',
        color: '#8B4513',
        description: '',
        parent_id: 263,
        created_at: '2021-06-29T10:31:32.346Z',
        updated_at: '2021-06-29T10:34:38.154Z',
        deleted_at: null,
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
      },
      {
        id: 265,
        name: 'Limitation tonnage',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/tonnage_limit_icon.svg',
        color: '#8B4513',
        description: '',
        parent_id: 263,
        created_at: '2021-06-29T10:32:54.462Z',
        updated_at: '2021-06-29T10:34:43.466Z',
        deleted_at: null,
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
      },
      {
        id: 267,
        name: 'Incident de la route info',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/roadway_incidents_icon.svg',
        color: '#8B4513',
        description: '',
        parent_id: 263,
        created_at: '2021-06-29T10:33:13.978Z',
        updated_at: '2021-06-29T10:34:52.548Z',
        deleted_at: null,
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
      },
      {
        id: 268,
        name: 'Route barrée',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/prohibited_traffic_icon.svg',
        color: '#8B4513',
        description: '',
        parent_id: 263,
        created_at: '2021-06-29T10:33:21.968Z',
        updated_at: '2021-06-29T10:34:56.581Z',
        deleted_at: null,
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
      },
      {
        id: 269,
        name: 'Danger routier',
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/roadway_danger_icon.svg',
        color: '#8B4513',
        description: '',
        parent_id: 263,
        created_at: '2021-06-29T10:33:34.279Z',
        updated_at: '2021-06-29T10:35:01.087Z',
        deleted_at: null,
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
      },
    ],
  },
  {
    id: 182,
    color: '#9F1122',
    name: 'Incident armé',
    subcategories: [
      {
        id: 352,
        name: 'Incident armé - Misc',
        color: '#9F1122',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
        parent_id: 182,
        created_at: null,
        updated_at: '2021-10-29T10:34:39.775Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 248,
        name: 'Incident armé : arme non-létales (gaz, canons à eau, etc.)',
        color: '#9F1122',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
        parent_id: 182,
        created_at: null,
        updated_at: '2021-06-15T09:30:32.010Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 247,
        name: `Incident à l'arme blanche ou contondante`,
        color: '#9F1122',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
        parent_id: 182,
        created_at: null,
        updated_at: '2021-06-15T09:28:25.157Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 222,
        name: 'Incident armé - petits calibres (bombardement)',
        color: '#9F1122',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 182,
        created_at: null,
        updated_at: '2021-06-11T14:54:07.151Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 221,
        name: `Incident armé - armes légères (Fusils d'assaut, pistolets, fusil de précision)`,
        color: '#9F1122',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 182,
        created_at: null,
        updated_at: '2021-06-14T14:28:23.911Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 179,
        name: 'Bombardement, engin explosif improvisé',
        color: '#9F1122',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 182,
        created_at: null,
        updated_at: '2021-06-15T09:26:38.443Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 175,
        name: 'Incident armé - armes lourdes (Tanks, Véhicules militaires blindés)',
        color: '#9F1122',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 182,
        created_at: null,
        updated_at: '2021-06-15T09:22:59.025Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 36,
        name: 'Assassinat',
        color: '#9F1122',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 182,
        created_at: null,
        updated_at: '2021-05-18T10:52:23.557Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
    ],
  },
  {
    id: 121,
    color: '#1E5A90',
    name: `Incidents technique`,
    subcategories: [
      {
        id: 364,
        name: 'Décès/Disparition',
        color: '#1E5A90',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 121,
        created_at: null,
        updated_at: '2021-06-29T15:34:34.743Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 272,
        name: 'Opération de sauvetage',
        color: '#1E5A90',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 121,
        created_at: null,
        updated_at: '2021-06-29T15:34:34.743Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 162,
        name: 'Famine',
        color: '#1E5A90',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
        parent_id: 121,
        created_at: null,
        updated_at: '2021-05-19T10:08:40.163Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 160,
        name: 'Accident Nautique',
        color: '#1E5A90',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 271,
        created_at: null,
        updated_at: '2021-06-29T10:37:23.111Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 161,
        name: 'Accident Routier',
        color: '#1E5A90',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 263,
        created_at: null,
        updated_at: '2021-07-07T14:50:46.455Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 230,
        name: 'Accident Ferroviaire',
        color: '#1E5A90',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 270,
        created_at: null,
        updated_at: '2021-06-29T10:36:19.441Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 284,
        name: 'Explosion',
        color: '#1E5A90',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 50,
        created_at: null,
        updated_at: '2021-07-06T15:34:34.204Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 279,
        name: 'Incendie',
        color: '#1E5A90',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 50,
        created_at: null,
        updated_at: '2021-07-06T14:26:50.568Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 274,
        name: 'Défaillance Bâtiment/Infrastructure',
        color: '#1E5A90',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
        parent_id: 353,
        created_at: null,
        updated_at: '2021-07-02T10:24:24.197Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 56,
        name: 'Matière dangereuse - Drogues',
        color: '#1E5A90',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 119,
        created_at: null,
        updated_at: '2021-05-10T16:17:50.107Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 55,
        name: 'Matière dangereuse - Poudre Suspecte',
        color: '#1E5A90',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
        parent_id: 119,
        created_at: null,
        updated_at: '2021-03-22T06:57:18.020Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 54,
        name: 'Matière dangereuse - Fause Alerte',
        color: '#1E5A90',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
        parent_id: 119,
        created_at: null,
        updated_at: '2021-03-22T06:57:00.716Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 53,
        name: 'Matière dangereuse - Chimique',
        color: '#1E5A90',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
        parent_id: 119,
        created_at: null,
        updated_at: '2021-03-22T06:56:44.206Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 52,
        name: 'Matière dangereuse - Odeurs/Fumées',
        color: '#1E5A90',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
        parent_id: 119,
        created_at: null,
        updated_at: '2021-03-22T06:56:30.745Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 51,
        name: 'Matière dangereuse - Other/Inconnu',
        color: '#1E5A90',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
        parent_id: 119,
        created_at: null,
        updated_at: '2021-03-22T06:56:19.358Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 49,
        name: 'Matière dangereuse - Essence/Huile/Gaz',
        color: '#1E5A90',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
        parent_id: 119,
        created_at: null,
        updated_at: '2021-03-22T06:55:53.938Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 48,
        name: 'Matière dangereuse - Chimique',
        color: '#1E5A90',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
        parent_id: 119,
        created_at: null,
        updated_at: '2021-03-22T06:55:21.168Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 47,
        name: 'Matière dangereuse- Biologique',
        color: '#1E5A90',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
        parent_id: 119,
        created_at: null,
        updated_at: '2021-03-22T06:55:04.008Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 363,
        name: 'Incident Electrique',
        color: '#1E5A90',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
        parent_id: 119,
        created_at: null,
        updated_at: '2021-03-22T06:55:04.008Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
    ],
  },
  {
    id: 356,
    color: '#ffbd3e',
    name: 'Irrespect de la loi',
    subcategories: [
      {
        id: 359,
        name: 'Accusation',
        color: '#ffbd3e',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
        parent_id: 356,
        created_at: null,
        updated_at: '2021-10-29T12:33:02.407Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 358,
        name: 'Arrestation',
        color: '#ffbd3e',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
        parent_id: 356,
        created_at: null,
        updated_at: '2021-10-29T12:33:02.396Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 357,
        name: 'Police',
        color: '#ffbd3e',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
        parent_id: 356,
        created_at: null,
        updated_at: '2021-10-29T12:33:02.389Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
    ],
  },
  {
    id: 125,
    color: '#FFAD0F',
    name: 'Crime',
    subcategories: [
      {
        id: 297,
        name: 'Incidents - armes à feux (fusillade)',
        color: '#FFAD0F',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 125,
        created_at: null,
        updated_at: '2021-07-26T13:21:44.390Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 294,
        name: `Incidents à l'arme blanche ou contondante`,
        color: '#FFAD0F',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 125,
        created_at: null,
        updated_at: '2021-07-26T13:21:02.795Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 280,
        name: 'Bombardement',
        color: '#FFAD0F',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
        parent_id: 125,
        created_at: null,
        updated_at: '2021-07-06T14:42:11.033Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 260,
        name: 'Actions de la force étatique',
        color: '#FFAD0F',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: null,
        geolocation: null,
        parent_id: 125,
        created_at: null,
        updated_at: '2021-06-28T11:53:56.717Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 219,
        name: 'Hijacking',
        color: '#FFAD0F',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 125,
        created_at: null,
        updated_at: '2021-06-28T11:43:01.244Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 217,
        name: 'Vandalisme',
        color: '#FFAD0F',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 125,
        created_at: null,
        updated_at: '2021-06-28T11:42:50.527Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 216,
        name: 'Vol / Cambriolage',
        color: '#FFAD0F',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 125,
        created_at: null,
        updated_at: '2021-06-28T11:43:46.601Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 213,
        name: `Assasinat / Tentative d'Assanitat Attempt / Homicide`,
        color: '#FFAD0F',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 125,
        created_at: null,
        updated_at: '2021-06-28T11:43:25.992Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 209,
        name: 'Cyber-crime / Cyber-attaque',
        color: '#FFAD0F',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 125,
        created_at: null,
        updated_at: '2021-06-28T11:45:38.138Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 38,
        name: 'Incendie criminel',
        color: '#FFAD0F',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 125,
        created_at: null,
        updated_at: '2021-06-28T11:42:43.237Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 37,
        name: 'Enlèvement / Kidnapping',
        color: '#FFAD0F',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 125,
        created_at: null,
        updated_at: '2021-05-18T10:52:47.820Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
      {
        id: 34,
        name: 'Menaces biologiques (enveloppes contaminées au bacille du charbon)',
        color: '#FFAD0F',
        description: '',
        kw_in: [],
        kw_ex: [],
        classifier_score: 0.9,
        geolocation: null,
        parent_id: 125,
        created_at: null,
        updated_at: '2021-06-11T10:15:30.602Z',
        deleted_at: null,
        icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
      },
    ],
  },
];
