import React, { useRef, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';

import { WidgetEditMode } from '@molecules/WidgetEditMode';

import { CustomDragLayer } from '@organisms/WidgetCustomDragLayer';

import { IWidget, useWidgetGridContext } from '@contexts/WidgetGridContext';

import { useGridHelper, ItemTypes } from '../../../hooks/useGridHelper';
import { useWidgetComponentSelector } from '../../../hooks/useWidgetComponentSelector';
import { Grid, GridContainer, GridWrapper } from './styles';

type DragIWidget = IWidget;

interface WidgetGridProps {
  onSaveWidgets: (widgets: IWidget[]) => void;
}

export const WidgetGrid = ({ onSaveWidgets }: WidgetGridProps) => {
  const { snapToGrid } = useGridHelper();
  const gridContainer = useRef<HTMLDivElement>(null);

  const {
    widgets,
    setWidgets,
    isColliding,
    gridSizeData,
    setGridSizeData,
    isEditMode,
    sidePanelData,
    setIsEditMode,
  } = useWidgetGridContext();

  const containerWidth = useRef(0);

  const [lowestWidgetPoint, setLowestWidgetPoint] = useState(0);
  const { getWidgetComponent, getWidgetSidePanelComponent } =
    useWidgetComponentSelector();

  const [, drop] = useDrop(() => ({
    accept: ItemTypes.WIDGET,
    drop(item: DragIWidget, monitor) {
      const delta = monitor.getDifferenceFromInitialOffset() as {
        x: number;
        y: number;
      };

      let left = Math.round(item.left + delta.x);
      let gridLeft = 0;
      let top = Math.round(item.top + delta.y);
      let gridTop = 0;

      if (gridContainer.current) {
        if (!isColliding({ ...item, left, top })) {
          [left, gridLeft, top, gridTop] = snapToGrid(
            left,
            top,
            item.width,
            item.height,
            containerWidth.current,
          );

          // we're actually checking for collision a second time with the snapped values : there's an edge case where you can move a widget to the edge (no collision area but out of bounds) which snaps the widget back inside and into a collision
          if (!isColliding({ ...item, left, top }))
            setWidgets((draft: IWidget[]) => {
              const widget = draft.find((w: IWidget) => w.id === item.id);
              if (widget) {
                // console.log(gridTop);
                widget.left = left;
                widget.grid_left = gridLeft;
                widget.top = top;
                widget.grid_top = gridTop;
              }
            });
        }
      }
      return undefined;
    },
  }));

  useEffect(() => {
    const handleWindowResize = () => {
      if (
        gridContainer.current &&
        gridContainer.current.getBoundingClientRect().width > 0
      ) {
        setGridSizeData({
          width: gridContainer.current.getBoundingClientRect().width,
          offsetTop: gridContainer.current.offsetTop,
          scrollTop: gridContainer.current.scrollTop,
        });
        containerWidth.current =
          gridContainer.current.getBoundingClientRect().width;
      }
    };

    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [gridContainer, setGridSizeData]);

  useEffect(() => {
    const widgetLowestPoints = widgets.map(
      widget => widget.grid_top + widget.grid_height,
    );
    setLowestWidgetPoint(Math.max(...widgetLowestPoints));
  }, [widgets]);

  return (
    <GridContainer>
      {isEditMode && <WidgetEditMode onSaveWidgets={onSaveWidgets} />}
      <GridWrapper
        ref={gridContainer}
        onDoubleClick={() => setIsEditMode(true)}
      >
        <CustomDragLayer />
        {gridSizeData && (
          <Grid
            innerWidth={gridSizeData.width}
            isEditMode={isEditMode}
            sidePanelIsOpen={sidePanelData.isOpened}
            lowestWidgetPoint={lowestWidgetPoint}
            ref={drop}
          >
            {widgets.map(widget =>
              getWidgetComponent({
                typeSlug: widget.type.slug,
                widgetData: widget,
              }),
            )}
          </Grid>
        )}
      </GridWrapper>
      {sidePanelData.isOpened &&
        sidePanelData.widget &&
        getWidgetSidePanelComponent({
          typeSlug: sidePanelData.widget.type.slug,
          widgetData: sidePanelData.widget,
        })}
    </GridContainer>
  );
};
