import styled, { css } from 'styled-components/native';
import { IconButton } from '@atoms/IconButton';
import { darken } from 'polished';
import { Dimensions } from 'react-native';

interface ButtonProps {
  isHovered?: boolean;
}
export const Button = styled(IconButton)<ButtonProps>`
  position: absolute;
  right: 20px;
  bottom: 12px;
  background-color: ${({ theme, isHovered }) =>
    isHovered ? darken(0.1, theme.purpleBlue) : theme.purpleBlue};
  width: 88px;
  height: 88px;
  border-radius: 44px;
  align-items: center;
  justify-content: center;
  z-index: 2;
  ${() => {
    const { width } = Dimensions.get('window');

    if (width < 1400) {
      return css`
        width: 72px;
        height: 72px;
        border-radius: 36px;
      `;
    }

    if (width < 1620) {
      return css`
        width: 80px;
        height: 80px;
        border-radius: 40px;
      `;
    }
    return css``;
  }}
`;
