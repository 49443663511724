import { MapView } from '@atoms/MapView';

import { Widget } from '@organisms/Widget';
import { MapData, mapWidgetinitialData } from '@organisms/MapWidget/SideBar';

import { IWidget } from '@contexts/WidgetGridContext';

interface MapWidgetProps {
  widgetData: IWidget<MapData>;
}

export const MapWidget = ({ widgetData }: MapWidgetProps) => {
  return (
    <Widget widgetData={widgetData}>
      <MapView mapStyle={widgetData.data.mapStyle} />
    </Widget>
  );
};

export type { MapData };
export { mapWidgetinitialData };
