import React, {
  useState,
  createContext,
  useContext,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { Updater, useImmer } from 'use-immer';
import cloneDeep from 'lodash.clonedeep';
import { Widget } from '@services/hooks/useQueryPage';
import { useGridHelper } from '../hooks/useGridHelper';

// eslint-disable-next-line @typescript-eslint/ban-types
interface WidgetData<D, C = {}> {
  name: string;
  grid_left: number;
  grid_top: number;
  grid_width: number;
  grid_height: number;
  data: D;
  config?: C;
  page_id: string;
  type_id: string;
  config_id?: string;
}

type CreateNewWidgetData<D, C> = Omit<
  WidgetData<D, C>,
  'grid_top' | 'grid_left'
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IWidget<D = any, C = any> extends Widget<D, C> {
  left: number;
  top: number;
  width: number;
  height: number;
}

interface ISidePanelData {
  isOpened: boolean;
  widget?: IWidget;
}

interface GridSizeData {
  width: number;
  scrollTop: number;
  offsetTop: number;
}

interface WidgetGridContextData {
  widgets: IWidget[];
  setWidgets: Updater<IWidget[]>;
  isColliding: (draggedWidget: IWidget) => boolean;
  // gridContainer: React.MutableRefObject<HTMLDivElement | null>;
  isEditMode: boolean;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  cancelEditMode: () => void;
  sidePanelData: ISidePanelData;
  setSidePanelData: Updater<ISidePanelData>;
  updateWidgetList: (widgetList: IWidget[]) => void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  createNewWidget: <T = undefined, C = undefined, U extends T = T>(
    data: CreateNewWidgetData<U, C>,
  ) => WidgetData<U, C>;
  updateWidgetData<D = undefined>(
    id: string,
    data: Partial<WidgetData<D>>,
  ): void;
  setGridSizeData: React.Dispatch<
    React.SetStateAction<GridSizeData | undefined>
  >;
  gridSizeData: GridSizeData | undefined;
}

const WidgetGridContext = createContext<WidgetGridContextData>(
  {} as WidgetGridContextData,
);

interface WidgetProviderProps {
  children: ReactNode;
}

const WidgetGridContextProvider = ({ children }: WidgetProviderProps) => {
  const { gridPosToPx, gridSizeToPx } = useGridHelper();

  const [widgets, setWidgets] = useImmer<IWidget[]>([]);

  const [gridSizeData, setGridSizeData] = useState<GridSizeData>();

  const widgetDataBackUp = useRef<IWidget[]>([]);

  // const gridContainer = useRef<HTMLDivElement>(null);

  const [isEditMode, setIsEditMode] = useState(false);

  const [sidePanelData, setSidePanelData] = useImmer<ISidePanelData>({
    isOpened: false,
  });

  const updateWidgetList = useCallback(
    (widgetList: IWidget[]) => {
      const innerWidth = gridSizeData?.width || window.innerWidth;
      setWidgets([
        ...widgetList.map(widget => ({
          ...widget,
          top: gridPosToPx(widget.grid_top, innerWidth, sidePanelData.isOpened),
          left: gridPosToPx(
            widget.grid_left,
            innerWidth,
            sidePanelData.isOpened,
          ),
          width: gridSizeToPx(
            widget.grid_width,
            innerWidth,
            sidePanelData.isOpened,
          ),
          height: gridSizeToPx(
            widget.grid_height,
            innerWidth,
            sidePanelData.isOpened,
          ),
        })),
      ]);
    },
    [
      gridPosToPx,
      gridSizeData?.width,
      gridSizeToPx,
      setWidgets,
      sidePanelData.isOpened,
    ],
  );

  const cancelEditMode = useCallback(() => {
    const innerWidth = gridSizeData?.width || window.innerWidth;
    setWidgets([
      ...widgetDataBackUp.current.map(widget => ({
        ...widget,
        top: gridPosToPx(widget.grid_top, innerWidth, sidePanelData.isOpened),
        left: gridPosToPx(widget.grid_left, innerWidth, sidePanelData.isOpened),
        width: gridSizeToPx(
          widget.grid_width,
          innerWidth,
          sidePanelData.isOpened,
        ),
        height: gridSizeToPx(
          widget.grid_height,
          innerWidth,
          sidePanelData.isOpened,
        ),
      })),
    ]);
  }, [
    gridPosToPx,
    gridSizeData?.width,
    gridSizeToPx,
    setWidgets,
    sidePanelData.isOpened,
  ]);

  const convertWidgetDataToPxUnits = useCallback(() => {
    // let sidePanelIsOpen: boolean;

    // setSidePanelData(sideData => {
    //   sidePanelIsOpen = sideData.isOpened;
    //   return sideData;
    // });
    setWidgets((draft: IWidget[]) => {
      // console.log(sidePanelIsOpen);
      const innerWidth = gridSizeData?.width || window.innerWidth;
      return draft.map(widget => ({
        ...widget,
        top: gridPosToPx(widget.grid_top, innerWidth, sidePanelData.isOpened),
        left: gridPosToPx(widget.grid_left, innerWidth, sidePanelData.isOpened),
        width: gridSizeToPx(
          widget.grid_width,
          innerWidth,
          sidePanelData.isOpened,
        ),
        height: gridSizeToPx(
          widget.grid_height,
          innerWidth,
          sidePanelData.isOpened,
        ),
      }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setWidgets, sidePanelData]);

  const isColliding = useCallback(
    (draggedWidget: IWidget) => {
      // console.log(widgets[0].left);

      let colliding = false;

      setWidgets(_widgets => {
        colliding = _widgets
          .filter(widget => widget.id !== draggedWidget.id)
          .some(
            testedWidget =>
              testedWidget.left < draggedWidget.left + draggedWidget.width &&
              testedWidget.left + testedWidget.width > draggedWidget.left &&
              testedWidget.top < draggedWidget.top + draggedWidget.height &&
              testedWidget.height + testedWidget.top > draggedWidget.top,
          );
        return _widgets;
      });
      return colliding;
    },
    [setWidgets],
  );

  const findTopLeftCornerPosition = useCallback(() => {
    // temporary stuff but i'm not sure i wanna invest my time in a box packing algorithm
    let lowestOccupiedXPos = 0;
    setWidgets(_widgets => {
      const widgetLowestPoints = _widgets.map(
        widget => widget.grid_top + widget.grid_height,
      );
      if (widgetLowestPoints.length > 0) {
        lowestOccupiedXPos = Math.max(...widgetLowestPoints);
      }

      return _widgets;
    });
    return { gridTop: lowestOccupiedXPos, gridLeft: 0 };
  }, [setWidgets]);

  const createNewWidget = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-types
    <D, C = undefined>({
      grid_width,
      grid_height,
      data,
      page_id,
      type_id,
      config_id,
      name,
      config,
    }: CreateNewWidgetData<D, C>): WidgetData<D, C> => {
      const { gridTop, gridLeft } = findTopLeftCornerPosition();
      const newWidget = {
        name,
        grid_top: gridTop,
        grid_left: gridLeft,
        grid_width,
        grid_height,
        data,
        page_id,
        type_id,
        config_id,
        config,
      };
      return newWidget;
    },
    [findTopLeftCornerPosition],
  );

  const updateWidgetData = useCallback(
    <D,>(id: string, data: Partial<WidgetData<D>>) => {
      setWidgets(draft => {
        const widget = draft.find((w: IWidget) => w.id === id);
        if (widget) {
          if (data.name) {
            widget.name = data.name;
          }
          widget.data = { ...widget.data, ...data.data };
        }
      });
    },
    [setWidgets],
  );

  useEffect(() => {
    // when we toggle on editmode, save the current state in a backup so that the cancel button can go back to it
    if (isEditMode) {
      widgetDataBackUp.current = cloneDeep(widgets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  useEffect(() => {
    convertWidgetDataToPxUnits();

    window.addEventListener('resize', convertWidgetDataToPxUnits);
    return () =>
      window.removeEventListener('resize', convertWidgetDataToPxUnits);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleKeyboard = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Escape':
          setIsEditMode(false);
          cancelEditMode();
          break;
        case 'Enter':
          setIsEditMode(false);
          break;
        case 'e':
        case 'E':
          if (event.shiftKey) setIsEditMode(true);
          break;

        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyboard);
    return () => window.removeEventListener('keydown', handleKeyboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    convertWidgetDataToPxUnits();
  }, [sidePanelData.isOpened, convertWidgetDataToPxUnits]);

  return (
    <WidgetGridContext.Provider
      value={{
        gridSizeData,
        setGridSizeData,
        widgets,
        setWidgets,
        isColliding,
        //  gridContainer,
        isEditMode,
        setIsEditMode,
        cancelEditMode,
        sidePanelData,
        setSidePanelData,
        updateWidgetList,
        createNewWidget,
        updateWidgetData,
      }}
    >
      {children}
    </WidgetGridContext.Provider>
  );
};

function useWidgetGridContext(): WidgetGridContextData {
  const context = useContext(WidgetGridContext);

  return context;
}

export { WidgetGridContextProvider, useWidgetGridContext };
