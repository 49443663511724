import { useState, useEffect } from 'react';
import { useRoute, RouteProp } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { Spinner } from '@atoms/Spinner';
import { LoadingModal } from '@atoms/LoadingModal';

import { WorkspacePageAddNewWidgetModal } from '@organisms/WorkspacePage/AddNewWidgetModal';

import { WorkspaceLayout } from '@templates/WorkspaceLayout';
import { WorkspaceTab } from '@templates/WorkspaceTab';
import { WorkspacePage } from '@templates/WorkspacePage';

import { useQueryWorkspace } from '@services/hooks/useQueryWorkspace';
import { useMutationCreatePage } from '@services/hooks/useMutationCreatePage';
import { useMutationDeletePage } from '@services/hooks/useMutationDeletePage';
import { useMutationUpdatePage } from '@services/hooks/useMutationUpdatePage';

import { LoadingContainer } from './styles.web';

type ParamList = {
  Workspace: {
    id: string;
  };
};

const Workspace = () => {
  const route = useRoute<RouteProp<ParamList, 'Workspace'>>();
  const { t } = useTranslation();
  const workspaceQuery = useQueryWorkspace(route.params.id);
  const mutationCreatePage = useMutationCreatePage({
    workspace_id: route.params.id,
  });
  const mutationDeletePage = useMutationDeletePage({
    workspace_id: route.params.id,
  });
  const mutationUpdatePage = useMutationUpdatePage({
    workspace_id: route.params.id,
  });

  const [selectedPage, setSelectedPage] = useState<string>();
  const [openCreateWidgetModal, setOpenCreateWidgetModal] = useState(false);

  const handleCreatePage = async () => {
    const newPage = await mutationCreatePage.mutateAsync({
      name: t('new_tab'),
      workspace_id: route.params.id,
    });
    setSelectedPage(newPage.id);
  };

  const handleRemovePage = async (id: string) => {
    await mutationDeletePage.mutateAsync(id);
  };

  const handleChangePageName = async (newName: string, pageId: string) => {
    await mutationUpdatePage.mutateAsync({
      page_id: pageId,
      data: { name: newName },
    });
  };

  useEffect(() => {
    if (workspaceQuery.data) {
      if (workspaceQuery.data.pages.length > 0) {
        setSelectedPage(workspaceQuery.data.pages[0].id);
      }
    }
  }, [workspaceQuery.data]);

  return (
    <WorkspaceLayout>
      {workspaceQuery.data ? (
        <WorkspaceTab
          workspaceName={workspaceQuery.data.name}
          tabs={workspaceQuery.data.pages}
          selectedTab={selectedPage}
          onChangeTabName={(name: string, tabKey: string) =>
            handleChangePageName(name, tabKey)
          }
          onChangeSelecteTab={(key: string) => setSelectedPage(key)}
          onRemoveTab={(key: string) => handleRemovePage(key)}
          onCreateTab={handleCreatePage}
          onBackPress={() => window.history.back()}
        >
          {selectedPage && (
            <WorkspacePage
              pageId={selectedPage}
              onCreateNewWidget={() => setOpenCreateWidgetModal(true)}
            />
          )}
        </WorkspaceTab>
      ) : (
        <LoadingContainer>
          <Spinner size="large" />
        </LoadingContainer>
      )}
      {openCreateWidgetModal && selectedPage && (
        <WorkspacePageAddNewWidgetModal
          pageId={selectedPage}
          isVisible={openCreateWidgetModal}
          onClose={() => setOpenCreateWidgetModal(false)}
        />
      )}
      {mutationDeletePage.isLoading && (
        <LoadingModal visible={mutationDeletePage.isLoading} />
      )}
    </WorkspaceLayout>
  );
};

export default Workspace;
