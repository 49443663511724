/* eslint-disable @typescript-eslint/no-empty-function */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWidgetGridContext, IWidget } from '@contexts/WidgetGridContext';
import { H6, Body1 } from '@nucleus/Typography';
import 'react-resizable/css/styles.css';
import { DropDown } from '@atoms/DropDown';
import { IconButton } from '@atoms/IconButton';
import { NameModal } from '@molecules/NameModal';
import { useWindowDimensions } from 'react-native';
import { Container } from './styles';
import filterIcon from '../../../assets/icons/filter_alt.svg';
import deleteIcon from '../../../assets/icons/delete.svg';
import editIcon from '../../../assets/icons/edit.svg';
import tuneIcon from '../../../assets/icons/tune.svg';
import moreIcon from '../../../assets/icons/more_vert.svg';

interface WidgetHeaderProps {
  widgetData: IWidget;
  onRename: (newName: string) => Promise<void>;
  onConfigure?: () => void;
  onDelete: () => void;
}

export const WidgetHeader = ({
  widgetData,
  onRename,
  onDelete,
  onConfigure,
}: WidgetHeaderProps) => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const [renameActivated, setRenameActivated] = useState(false);
  const { setSidePanelData } = useWidgetGridContext();

  const handleRename = async (newName: string) => {
    await onRename(newName);
    setRenameActivated(false);
  };

  return (
    <Container>
      {width < 1350 ? (
        <Body1 style={{ fontWeight: '800' }}>{widgetData.name}</Body1>
      ) : (
        <H6>{widgetData.name}</H6>
      )}

      <div className="options">
        <IconButton
          icon={filterIcon}
          onPress={() =>
            setSidePanelData(draft => ({
              isOpened: !draft.isOpened,
              widget: widgetData,
            }))
          }
          fill="white"
        />

        <DropDown
          icon={moreIcon}
          iconFill="white"
          options={
            onConfigure
              ? [
                  {
                    label: 'Rename Widget',
                    value: 'rename',
                    icon: editIcon,
                    iconWidth: 14,
                    iconHeight: 14,
                    iconViewBox: '0 0 12 12',
                    onPress: () => setRenameActivated(true),
                  },
                  {
                    label: 'Configure Widget',
                    value: 'configure',
                    icon: tuneIcon,
                    iconWidth: 16,
                    iconHeight: 16,
                    iconViewBox: '0 0 24 24',
                    iconFill: 'white',
                    onPress: onConfigure,
                  },
                  {
                    label: 'Delete Widget',
                    value: 'delete',
                    icon: deleteIcon,
                    iconWidth: 14,
                    iconHeight: 14,
                    iconViewBox: '0 0 20 20',
                    onPress: onDelete,
                  },
                ]
              : [
                  {
                    label: 'Rename Widget',
                    value: 'rename',
                    icon: editIcon,
                    iconWidth: 14,
                    iconHeight: 14,
                    iconViewBox: '0 0 12 12',
                    onPress: () => setRenameActivated(true),
                  },
                  {
                    label: 'Delete Widget',
                    value: 'delete',
                    icon: deleteIcon,
                    iconWidth: 14,
                    iconHeight: 14,
                    iconViewBox: '0 0 20 20',
                    onPress: onDelete,
                  },
                ]
          }
        />
      </div>
      {renameActivated && (
        <NameModal
          isVisible={renameActivated}
          title={t('rename_widget')}
          oldName={widgetData.name}
          onCancel={() => setRenameActivated(false)}
          onConfirm={handleRename}
        />
      )}
    </Container>
  );
};
