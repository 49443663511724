import { H5 } from '@nucleus/Typography';
import { Container } from './styles';

export const CustomEventConfigPreview = () => {
  return (
    <Container>
      <H5>Preview</H5>
    </Container>
  );
};
