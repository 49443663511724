import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useToastContext } from '@contexts/ToastContext';
import { queryClient } from '@services/queryClient';
import { api } from '@services/userManagementApi';

export async function deleteWorkspace(workspace_id: string) {
  await api.delete(`/v1/workspaces/${workspace_id}`);
}

export const useMutationDeleteWorkspace = () => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async (workspace_id: string) => {
      await deleteWorkspace(workspace_id);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['workspaces']);
        dispatchToast({
          message: t('success_delete_dashboard'),
          type: 'success',
        });
      },
      onError: () => {
        dispatchToast({
          message: t('error_delete_dashboard'),
          type: 'error',
        });
      },
    },
  );
};
