import { Widget } from '@organisms/Widget';
import { IWidget } from '@contexts/WidgetGridContext';
import { CustomEventWidgetConfigData } from '@templates/CustomEventConfig/types';
import { CustomEventWidgetData } from './types';

interface CustomEventWidgetProps {
  widgetData: IWidget<CustomEventWidgetData, CustomEventWidgetConfigData>;
}

export const CustomEventWidget = ({ widgetData }: CustomEventWidgetProps) => {
  return (
    <Widget widgetData={widgetData}>
      <h5>{widgetData.id}</h5>
    </Widget>
  );
};
