/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState,
  useCallback,
  createContext,
  useContext,
  useEffect,
  ReactNode,
} from 'react';
import jwt_decode from 'jwt-decode';
import { AxiosError } from 'axios';
import { useToastContext } from './ToastContext';
import { storeState, getState, removeState } from '../utils/asyncStorage';
import { api } from '../services/userManagementApi';

import {
  mediterrainSetup,
  rmdSetup,
  epkSetup,
  testSetup,
} from '../utils/defaultSetups';

const mediterraiSetupOrgList = [
  '1520eac6-d33d-4bd3-beb6-b2e4611d75c7',
  'a64b594b-28d6-4290-928a-f8a4c3955ee2',
];

const rmdSetupOrgList = [
  'ea9e9ed9-f31a-4244-868e-30b76bdcb2c0',
  '8d6630d4-adee-46a7-89fb-bb843c31bb4c',
];

const epkSetupOrgList = ['7b660e71-604c-4ab0-a0cb-961f1f6a0ebe'];

const scutumOrgList = ['beb01c12-a470-4753-861d-6be8c0a820e4'];

interface JWTProps {
  roles: string[];
  iat: number;
  exp: number;
  sub: string;
}

export interface Organization {
  id: string;
  name: string;
  setup?: {
    typeMainCouranteActionToShow?: 'opened' | 'closed' | 'both';
    defaultDynamicDataLocation?: any;
    showAllActions?: boolean;
    defaultMapStyle: string;
    autoFocusOff?: boolean;
    mainCouranteByName?: string;
    centerGeometry?: {
      type: 'Polygon';
      coordinates: number[][][];
    };
    limitGeometry?: {
      type: 'Polygon';
      coordinates: number[][][];
    };
    extraServices: string[];
    categories: {
      id: number;
      name: string;
      color: string;
      subcategories: {
        id: number;
        name: string;
        icon: string;
        color: string;
        description: string;
        kw_in: never[];
        kw_ex: never[];
        classifier_score: number;
        geolocation: null;
        parent_id: number;
        created_at: null;
        updated_at: string;
        deleted_at: null;
      }[];
    }[];
  };
}

export interface Team {
  id: string;
  name: string;
}

export interface UserProps {
  id: string;
  email: string;
  name: string;
  roles: string[];
  teams: Team[];
  managed_teams: Team[];
  organization: Organization;
}

interface SignInCredentials {
  email: string;
  password: string;
  stay_connected: boolean;
}

interface AuthContextData {
  loading: boolean;
  user: UserProps | null;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContextProvider = ({ children }: AuthProviderProps) => {
  const { dispatchToast } = useToastContext();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<UserProps | null>(null);

  async function signIn({ email, password }: SignInCredentials) {
    setLoading(true);
    try {
      const { data } = await api.post('/sessions', { email, password });

      const decodedToken = jwt_decode<JWTProps>(data.token);

      const isChallengeUser = decodedToken.roles.find(
        role => role === 'challenge.user' || role === 'workspaces.service',
      );

      if (!isChallengeUser) {
        dispatchToast({
          message: 'You not have authorization to use this service',
          type: 'error',
        });
        return;
      }

      api.defaults.headers.Authorization = `Bearer ${data.token}`;

      let loggedUser = data.user;

      if (mediterraiSetupOrgList.includes(data.user.organization.id)) {
        loggedUser = {
          ...data.user,
          organization: {
            ...data.user.organization,
            setup: mediterrainSetup,
          },
        };
      }

      if (rmdSetupOrgList.includes(data.user.organization.id)) {
        loggedUser = {
          ...data.user,
          organization: {
            ...data.user.organization,
            setup: rmdSetup,
          },
        };
      }

      if (epkSetupOrgList.includes(data.user.organization.id)) {
        loggedUser = {
          ...data.user,
          organization: {
            ...data.user.organization,
            setup: epkSetup,
          },
        };
      }

      if (scutumOrgList.includes(data.user.organization.id)) {
        loggedUser = {
          ...data.user,
          organization: {
            ...data.user.organization,
            setup: testSetup,
          },
        };
      }

      storeState('user', loggedUser);
      storeState('token', data.token);
      setUser(loggedUser);

      setLoading(false);
    } catch (err: any) {
      dispatchToast({
        message: err.response?.data?.message
          ? err.response.data.message
          : 'Error on login try again',
        type: 'error',
      });
      setLoading(false);
    }
  }

  const signOut = useCallback(async () => {
    await removeState('token');
    await removeState('user');
    setUser(null);
  }, []);

  useEffect(() => {
    async function loadStorageData() {
      setLoading(true);
      const storedUser = await getState<UserProps>('user');
      const storedToken = await getState<string>('token');

      if (!storedUser || !storedToken) {
        signOut();
        setLoading(false);
        return;
      }

      const decodedToken = jwt_decode<JWTProps>(storedToken);
      if (new Date().getTime() / 1000 > decodedToken.exp) {
        signOut();
        return;
      }

      api.defaults.headers.Authorization = `Bearer ${storedToken}`;

      setUser(storedUser);

      setLoading(false);
    }
    loadStorageData();
  }, [signOut]);

  api.interceptors.response.use(
    response => {
      return response;
    },
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        if (error.response.data?.message === 'Invalid JWT token') {
          signOut();
        }
      }
    },
  );

  return (
    <AuthContext.Provider value={{ loading, user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuthContext(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}

export { AuthContextProvider, useAuthContext };
