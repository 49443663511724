export interface CustomEventWidgetData {
  custom_event: string[];
}

export interface CustomEventWidgetInitialData {
  grid_height: number;
  grid_width: number;
  data: CustomEventWidgetData;
}

export const customEventWidgetInitialData: CustomEventWidgetInitialData = {
  grid_height: 6,
  grid_width: 4,
  data: {
    custom_event: [],
  },
};
