import React, { useState, useEffect, useMemo, BaseSyntheticEvent } from 'react';
import { RndDragEvent, DraggableData } from 'react-rnd';
import { useTranslation } from 'react-i18next';
import { MainCouranteInfoConfirmCloseModal } from '@molecules/MainCouranteInfoConfirmCloseModal';
import { MainCouranteInfoDeleteMainCouranteModal } from '@molecules/MainCouranteInfoDeleteMainCouranteModal';
import { MainCouranteInfoDeleteMainCouranteActionModal } from '@molecules/MainCouranteInfoDeleteMainCouranteActionModal';
import { MainCouranteInfoOverview } from '@organisms/MainCouranteInfoOverview';
import { UpdateMainCouranteFormData } from '@molecules/MainCouranteInfoOverviewInfosForm';
import { MainCouranteInfoActions } from '@organisms/MainCouranteInfoActions';
import { MainCouranteInfoHeader } from '@organisms/MainCouranteInfoHeader';
import {
  MainCouranteInfoDetail,
  UpdateMainCouranteActionFormData,
} from '@organisms/MainCouranteInfoDetail';
import { useCreateLocationContext } from '@contexts/CreateLocationContext';
import { useEventsContext } from '@contexts/EventsContext';
import { useAuthContext } from '@contexts/AuthContext';
import { useQueryFiltredMainCourantes } from '@services/hooks/useQueryFiltredMainCourantes';
import { useQueryMainCouranteInfo } from '@services/hooks/useQueryMainCouranteInfo';
import { useActivatedMainCouranteActionsContext } from '@contexts/ActivatedMainCouranteActions';
import { useTabsControlContext } from '@contexts/TabsControlContext';
import { useMutationCloseMainCourante } from '@services/hooks/useMutationCloseMainCourante';
import { useFiltersContext } from '@contexts/FiltersContext';
import { useZonesContext } from '@contexts/ZonesContext';
import { Button } from '@atoms/Button';
import { useForm } from 'react-hook-form';
import { useMutationUpdateMainCouranteAction } from '@services/hooks/useMutationUpdateMainCouranteAction';
import { useMutationUpdateMainCourante } from '@services/hooks/useMutationUpdateMainCourante';
import { useMutationDeleteMainCourante } from '@services/hooks/useMutationDeleteMainCourante';
import { useMutationDeleteMainCouranteAction } from '@services/hooks/useMutationDeleteMainCouranteAction';
import { setHours, setMinutes, getHours, getMinutes } from 'date-fns';
import { Size } from '../../../utils/tabApi';
import { useScreenControl } from '../../../hooks/useScreenControl';

import {
  Container,
  ContentContainer,
  LeftContainer,
  RightContainer,
  Footer,
} from './styles';

interface EventDetailProps {
  parentSize: Size;
}

export const MainCouranteInfo = ({ parentSize }: EventDetailProps) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { selectedTab } = useTabsControlContext();
  const { filters, getFiltersInParams } = useFiltersContext();

  const { updateActivatedEventId, activatedEventId } = useEventsContext();

  const { createLocationOpened, selectedLocation } = useCreateLocationContext();

  const { data: mainCourantes } = useQueryFiltredMainCourantes(
    selectedTab,
    filters,
    getFiltersInParams,
  );

  const { removeZones, addNewZone } = useZonesContext();

  const {
    setListOfActivedMainCouranteActions,
    activatedActionId,
    updateActivatedActionId,
  } = useActivatedMainCouranteActionsContext();

  const {
    screenSize,
    screenPosition,
    isFullscreen,
    handleFullScreen,
    setScreenSize,
    setScreenPosition,
  } = useScreenControl({ parentSize });

  const event = useMemo(
    () => mainCourantes?.find(({ id }) => id === activatedEventId),
    [activatedEventId, mainCourantes],
  );

  const { data: mainCouranteDetail, isLoading } = useQueryMainCouranteInfo(
    event?.id || '',
  );

  const mainCouranteUpdateMutation = useMutationUpdateMainCourante({
    tabId: selectedTab,
    filters,
  });
  const updateMainCouranteActionMutation = useMutationUpdateMainCouranteAction(
    event?.id || '',
  );
  const closeMainCouranteMutation = useMutationCloseMainCourante({
    main_courante_id: event?.id,
    tabId: selectedTab,
    filters,
  });

  const deleteMainCouranteMutation = useMutationDeleteMainCourante({
    tabId: selectedTab,
  });

  const deleteMainCouranteActionMutation = useMutationDeleteMainCouranteAction({
    courante_id: event?.id || '',
  });

  const updateMainCouranteForm = useForm<UpdateMainCouranteFormData>();

  const updateMainCouranteActionForm =
    useForm<UpdateMainCouranteActionFormData>();

  const [
    confirmCloseMainCouranteModalOpen,
    setConfirmCloseMainCouranteModalOpen,
  ] = useState(false);

  const [
    confirmDeleteMainCouranteModalOpen,
    setConfirmDeleteMainCouranteModalOpen,
  ] = useState(false);

  const [
    confirmDeleteMainCouranteActionModalOpen,
    setConfirmDeleteMainCouranteActionModalOpen,
  ] = useState(false);

  const [selectedAction, setSelectedAction] = useState<string>();

  const [editMode, setEditMode] = useState(false);

  const mainCouranteActionInfo = useMemo(() => {
    return mainCouranteDetail?.main_courante_actions.find(
      ({ id }) => id === selectedAction,
    );
  }, [mainCouranteDetail?.main_courante_actions, selectedAction]);

  const onSubmitUpdateMainCourante = async (
    updateMainCouranteData: UpdateMainCouranteFormData,
  ) => {
    const mountedDate =
      updateMainCouranteData.date && updateMainCouranteData.hour
        ? setHours(
            setMinutes(
              updateMainCouranteData.date,
              getMinutes(updateMainCouranteData.hour),
            ),
            getHours(updateMainCouranteData.hour),
          )
        : updateMainCouranteData.date;

    await mainCouranteUpdateMutation.mutateAsync({
      main_courante_id: event?.id || '',
      name: updateMainCouranteData.name,
      date: mountedDate,
      location_color: updateMainCouranteData.location_color,
      location_name: updateMainCouranteData.location_name,
      location_coordinates: updateMainCouranteData.location_coordinates,
    });
  };

  const onSubmitUpdateMainCouranteAction = async (
    updateMainCouranteActionData: UpdateMainCouranteActionFormData,
  ) => {
    if (!selectedAction) return;
    const mountedDate =
      updateMainCouranteActionData.date && updateMainCouranteActionData.hour
        ? setHours(
            setMinutes(
              updateMainCouranteActionData.date,
              getMinutes(updateMainCouranteActionData.hour),
            ),
            getHours(updateMainCouranteActionData.hour),
          )
        : updateMainCouranteActionData.date;

    await updateMainCouranteActionMutation.mutateAsync({
      main_courante_action_id: selectedAction,
      ...updateMainCouranteActionData,
      date: mountedDate,
    });
  };

  const handleSubmitUpdates =
    (
      handleSubmitMainCouranteUpdate: (
        e?: React.BaseSyntheticEvent | undefined,
      ) => Promise<void>,
      handleSubmitMainCouranteActionUpdate: (
        e?: React.BaseSyntheticEvent | undefined,
      ) => Promise<void>,
    ) =>
    async (e: BaseSyntheticEvent) => {
      await handleSubmitMainCouranteUpdate(e);
      await handleSubmitMainCouranteActionUpdate(e);
      setEditMode(false);
    };

  const handleClose = () => {
    updateActivatedEventId(null);
    updateActivatedActionId(null);
    if (!user?.organization.setup?.showAllActions) {
      setListOfActivedMainCouranteActions([]);
    }
  };

  const handleDeleteMainCourante = async () => {
    await deleteMainCouranteMutation.mutateAsync({
      main_courante_id: event?.id || '',
    });
  };

  const handleDeleteMainCouranteAction = async () => {
    if (!selectedAction) return;
    await deleteMainCouranteActionMutation.mutateAsync({
      main_courante_action_id: selectedAction,
    });
    setConfirmDeleteMainCouranteActionModalOpen(false);
  };

  const handleChangeMainCouranteClosedStatus = async (closed: boolean) => {
    await closeMainCouranteMutation.mutateAsync({
      closed,
    });
  };

  useEffect(() => {
    removeZones('main_courante');
    if (event?.zone) {
      addNewZone(event.zone);
    }
  }, [addNewZone, event, removeZones]);

  useEffect(() => {
    updateMainCouranteActionForm.reset();
    if (!activatedActionId) return;
    setSelectedAction(activatedActionId);
  }, [activatedActionId, updateMainCouranteActionForm]);

  useEffect(() => {
    updateMainCouranteActionForm.reset();
  }, [activatedEventId, updateMainCouranteActionForm]);

  useEffect(() => {
    setEditMode(false);
  }, [selectedAction]);

  useEffect(() => {
    if (selectedLocation?.zone) {
      if (selectedLocation.zone?.properties?.type === 'main_courante') {
        updateMainCouranteForm.setValue(
          'location_coordinates',
          selectedLocation.zone.geometry,
        );
        updateMainCouranteForm.setValue(
          'location_name',
          selectedLocation.location_name,
        );
        updateMainCouranteForm.setValue(
          'location_color',
          selectedLocation.location_color,
        );
      }
      if (selectedLocation.zone.properties?.type === 'main_courante_action') {
        updateMainCouranteActionForm.setValue(
          'location_coordinates',
          selectedLocation.zone.geometry,
        );
        updateMainCouranteActionForm.setValue(
          'location_name',
          selectedLocation.location_name,
        );
        updateMainCouranteActionForm.setValue(
          'location_color',
          selectedLocation.location_color,
        );
      }
    }
  }, [selectedLocation, updateMainCouranteActionForm, updateMainCouranteForm]);

  useEffect(() => {
    if (user?.organization.setup?.showAllActions) return;
    setListOfActivedMainCouranteActions([]);
    if (mainCouranteDetail?.main_courante_actions) {
      setListOfActivedMainCouranteActions(
        mainCouranteDetail.main_courante_actions,
      );
    }
  }, [
    mainCouranteDetail,
    setListOfActivedMainCouranteActions,
    user?.organization.setup?.showAllActions,
  ]);

  if (!event) return null;

  return (
    <Container
      cancel="textarea"
      bounds="parent"
      minWidth={750}
      minHeight={450}
      size={screenSize}
      position={screenPosition}
      enableResizing={!isFullscreen}
      disableDragging={isFullscreen}
      onDragStop={(e: RndDragEvent, d: DraggableData) => {
        setScreenPosition({
          x: d.x,
          y: d.y,
        });
      }}
      onResize={(
        e: MouseEvent | TouchEvent,
        dir: unknown,
        elementRef: HTMLElement,
      ) => {
        setScreenSize({
          width: elementRef.offsetWidth,
          height: elementRef.offsetHeight,
        });
      }}
      hidden={!!createLocationOpened}
    >
      <MainCouranteInfoHeader
        onDeletePress={() => setConfirmDeleteMainCouranteModalOpen(true)}
        onEditPress={() => setEditMode(oldStatus => !oldStatus)}
        onFullScreenPress={handleFullScreen}
        onClosePress={handleClose}
      />
      <ContentContainer isEditableMode={editMode}>
        <LeftContainer>
          <MainCouranteInfoOverview
            id={event.id}
            formControl={updateMainCouranteForm.control}
            editMode={editMode}
            openCloseMainCouranteLoading={closeMainCouranteMutation.isLoading}
            onOpenMainCourantePress={() =>
              handleChangeMainCouranteClosedStatus(false)
            }
            onCloseMainCourantePress={() =>
              setConfirmCloseMainCouranteModalOpen(true)
            }
            actor_id={event.user_id}
            closed={event.closed}
            name={event.name}
            intensity={event.priority}
            date={event.date}
            formatted_date={event.formatted_date}
            location_name={event.location_name}
          />

          <MainCouranteInfoActions
            eventId={event.id}
            selectedAction={activatedActionId}
            isLoading={isLoading}
            main_courante_actions={mainCouranteDetail?.main_courante_actions}
            onActionSelect={updateActivatedActionId}
          />
        </LeftContainer>

        <RightContainer>
          {mainCouranteActionInfo && (
            <MainCouranteInfoDetail
              onDeleteAction={() =>
                setConfirmDeleteMainCouranteActionModalOpen(true)
              }
              formControl={updateMainCouranteActionForm.control}
              editMode={editMode}
              eventId={event.id}
              team_id={event.team_id}
              mainCouranteActionInfo={mainCouranteActionInfo}
            />
          )}
        </RightContainer>
      </ContentContainer>

      <MainCouranteInfoConfirmCloseModal
        isOpened={confirmCloseMainCouranteModalOpen}
        loading={closeMainCouranteMutation.isLoading}
        onConfirm={() => {
          handleChangeMainCouranteClosedStatus(true);
          setConfirmCloseMainCouranteModalOpen(false);
        }}
        onClose={() => setConfirmCloseMainCouranteModalOpen(false)}
      />

      <MainCouranteInfoDeleteMainCouranteModal
        isOpened={confirmDeleteMainCouranteModalOpen}
        loading={deleteMainCouranteMutation.isLoading}
        onConfirm={() => {
          handleDeleteMainCourante();
          setConfirmDeleteMainCouranteModalOpen(false);
        }}
        onClose={() => setConfirmDeleteMainCouranteModalOpen(false)}
      />

      <MainCouranteInfoDeleteMainCouranteActionModal
        isOpened={confirmDeleteMainCouranteActionModalOpen}
        loading={deleteMainCouranteActionMutation.isLoading}
        onConfirm={() => {
          handleDeleteMainCouranteAction();
          setConfirmDeleteMainCouranteActionModalOpen(false);
        }}
        onClose={() => setConfirmDeleteMainCouranteActionModalOpen(false)}
      />

      {editMode && (
        <Footer>
          <Button
            style={{ maxWidth: 120, maxHeight: 28 }}
            onPress={handleSubmitUpdates(
              updateMainCouranteForm.handleSubmit(onSubmitUpdateMainCourante),
              updateMainCouranteActionForm.handleSubmit(
                onSubmitUpdateMainCouranteAction,
              ),
            )}
            loading={
              updateMainCouranteForm.formState.isSubmitting ||
              updateMainCouranteActionForm.formState.isSubmitting
            }
          >
            {t('update')}
          </Button>
        </Footer>
      )}
    </Container>
  );
};
