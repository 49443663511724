/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { Geometry as NebulaGeometry } from '@nebula.gl/edit-modes';
import { SearchParamsData, Category } from '@contexts/FiltersContext';
import { Filters } from 'utils/tabApi';
import fr from 'date-fns/locale/fr';
import { api } from '../eventApi';
import { categories as normalCategories } from '../../utils/categories';
import { frCategories } from '../../utils/frCategories';
import { getDateFnsLocale } from '../../utils/getDateFnsLocale';

export type Geometry = NebulaGeometry;

export interface SourceCount {
  source_type_count: number;
  source_type_name: string;
}
export interface Event {
  type: 'normal';
  id: number;
  title: string;
  tags: string[];
  intensity: number;
  contents_count: number;
  location_name: string;
  location_coordinates: {
    type: string;
    coordinates: number[];
  };
  gdelt_id: number;
  created_at: string;
  updated_at: string;
  category: Category;
  sources_count: SourceCount[];
  formatted_created_at: string;
  formatted_updated_at: string;
}

export async function getEvents({
  bodyParams,
  queryParams,
}: SearchParamsData): Promise<Event[]> {
  const locale = getDateFnsLocale();

  const baseCategories = locale === fr ? frCategories : normalCategories;

  const allCategories = baseCategories.reduce((accum, curr) => {
    return [...accum, ...curr.subcategories];
  }, [] as Category[]);

  let events: Event[] = [];

  if (
    bodyParams.main_courante_ids &&
    bodyParams.main_courante_ids?.length > 0
  ) {
    return [];
  }

  try {
    const response = await api.post(
      'v1/search/events',
      bodyParams.coordinates
        ? {
            coordinates: bodyParams.coordinates,
          }
        : undefined,
      {
        params: { ...queryParams, teams: undefined },
      },
    );

    events = response.data.map((event: any) => ({
      ...event.event,
      tags: event.event.tags || [],
      category:
        allCategories.find((cat: any) => cat.id === event.category.id) ||
        event.category,
      sources_count: event.sources_count ? event.sources_count : [],
      type: 'normal',
    }));
  } catch (err) {
    events = [];
  }

  const normalizeData = events
    .filter(
      event =>
        event.location_coordinates?.coordinates[1] > -90 &&
        event.location_coordinates?.coordinates[1] <= 90 &&
        event.location_coordinates?.coordinates[0] >= -180 &&
        event.location_coordinates?.coordinates[0] <= 180,
    )
    .map(event => ({
      ...event,
      location_coordinates: {
        ...event.location_coordinates,
        coordinates: [
          Number(event.location_coordinates.coordinates[0]),
          Number(event.location_coordinates.coordinates[1]),
        ],
      },
    }))
    .map(event => {
      return {
        ...event,
        formatted_created_at: formatDistanceToNow(parseISO(event.created_at), {
          locale,
          addSuffix: true,
        }),
        formatted_updated_at: formatDistanceToNow(parseISO(event.updated_at), {
          locale,
          addSuffix: true,
        }),
      };
    });

  return normalizeData as any;
}

export function useQueryFiltredEvents(
  tabId: string | undefined,
  filters: Filters,
  getFiltersInParams: (filters: Filters) => SearchParamsData,
) {
  return useQuery(
    ['events', tabId, filters],
    () => getEvents(getFiltersInParams(filters)),
    {
      enabled: !!tabId,
      refetchOnWindowFocus: false,
      refetchInterval: 5 * 60000, // 5 minutes,
      refetchIntervalInBackground: true,
    },
  );
}
