import { useRef, useState } from 'react';
import { useHover } from 'react-native-web-hooks';
import { useTranslation } from 'react-i18next';

import { Image } from '@nucleus/Image';
import { Body1, Caption } from '@nucleus/Typography';

import { UserAvatar } from '@atoms/UserAvatar';
import { DropDown } from '@atoms/DropDown';
import { NameModal } from '@molecules/NameModal';

import { useQueryPublicUser } from '@services/hooks/useQueryPublicUser';

import previewWorkspace from '../../../../assets/preview_workspace.png';
import moreIcon from '../../../../assets/icons/more_vert.svg';
import deleteIcon from '../../../../assets/icons/delete.svg';
import editIcon from '../../../../assets/icons/edit.svg';
import { Container, InfoContainer, InfoRight } from './styles';

interface WorkspaceAreaAddButtonProps {
  onDelete: () => void;
  onRename: (newName: string) => Promise<void>;
  onPress: () => void;
  name: string;
  formatedDate: string;
  userId: string;
}

export const WorkspacesAreaCard = ({
  onDelete,
  onRename,
  onPress,
  name,
  formatedDate,
  userId,
}: WorkspaceAreaAddButtonProps) => {
  const contianerRef = useRef(null);
  const isHovered = useHover(contianerRef);

  const publicUserQuery = useQueryPublicUser(userId);

  const [isRenamedActive, setIsRenamedActive] = useState(false);
  const { t } = useTranslation();

  const updateName = async (newName: string) => {
    await onRename(newName);
    setIsRenamedActive(false);
  };

  return (
    <Container
      ref={contianerRef}
      isHovered={isHovered}
      onPress={onPress}
      disabled={isRenamedActive}
    >
      <Image
        source={previewWorkspace as string}
        style={{
          width: '100%',
          height: '75%',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          filter: 'brightness(60%)',
          boxShadow: '0px 16px 15px rgba(0, 0, 0, 0.25)',
        }}
      />
      <DropDown
        style={{ position: 'absolute', right: 8, top: 8 }}
        icon={moreIcon}
        iconFill="white"
        options={[
          {
            label: t('rename_dashboard'),
            value: 'rename_dashboard',
            icon: editIcon,
            iconWidth: 14,
            iconHeight: 14,
            iconViewBox: '0 0 12 12',
            onPress: () => setIsRenamedActive(true),
          },
          {
            label: t('delete_dashboard'),
            value: 'delete_dashboard',
            icon: deleteIcon,
            iconWidth: 14,
            iconHeight: 14,
            iconViewBox: '0 0 20 20',
            onPress: () => onDelete(),
          },
        ]}
      />
      <InfoContainer>
        <InfoRight>
          <Body1>{name}</Body1>
          <Caption style={{ marginTop: 4 }}>{`${t(
            'last_edited',
          )} ${formatedDate}`}</Caption>
        </InfoRight>
        <UserAvatar
          fallbackName={publicUserQuery.data?.name || ''}
          color="secondary"
        />
      </InfoContainer>
      {isRenamedActive && (
        <NameModal
          title={t('rename_dashboard')}
          placeholder={t('name_of_the_dashboard')}
          oldName={name}
          isVisible={isRenamedActive}
          onConfirm={updateName}
          onCancel={() => setIsRenamedActive(false)}
        />
      )}
    </Container>
  );
};
