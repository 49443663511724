import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  height: 60%;
  border-radius: 8px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.purpleBlue};
  padding: 16px;
`;

export const HeaderContainer = styled.View`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;
