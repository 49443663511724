import { Input } from '@atoms/Workspace/Input';
import { Button } from '@atoms/Button';
import { useTranslation } from 'react-i18next';
import searchIcon from '../../../../assets/icons/search.svg';
import { Container, HeaderContainer } from './styles';

interface CustomEventConfigInputSelectorProps {
  onNewInput: () => void;
}

export const CustomEventConfigInputSelector = ({
  onNewInput,
}: CustomEventConfigInputSelectorProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <HeaderContainer>
        <Input
          placeholder="Search"
          icon={searchIcon}
          style={{ maxWidth: '70%' }}
        />
        <Button onPress={onNewInput}>{t('create_new_input')}</Button>
      </HeaderContainer>
    </Container>
  );
};
