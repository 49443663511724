import { TopMenuTextButton } from '@atoms/TopMenuTextButton';
import { TouchableOpacity } from 'react-native';
import { Image } from '@nucleus/Image';
import { Icon } from '@nucleus/Icon';
import { useNavigation } from '@react-navigation/native';
import { SearchInput } from '@atoms/SearchInput';
import logo from '../../../../assets/rectangle_logo.png';
import alertIcon from '../../../../assets/icons/alert.svg';
import perfilIcon from '../../../../assets/icons/perfil.svg';
import { Container, Menu } from './styles';

export const TopMenu = () => {
  const { navigate } = useNavigation();
  return (
    <Container>
      <TouchableOpacity onPress={() => navigate('Dashboards')}>
        <Image source={logo as string} />
      </TouchableOpacity>

      <section>
        <SearchInput />
        <Menu>
          <TopMenuTextButton
            text="Dashboards"
            onPress={() => navigate('Dashboards')}
            isSelected
          />
          <TopMenuTextButton
            text="Saved configs"
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onPress={() => {}}
            isSelected={false}
          />
        </Menu>
      </section>
      <section>
        <Icon as={alertIcon} />
        <Icon as={perfilIcon} style={{ marginLeft: 32 }} />
      </section>
    </Container>
  );
};
