import React from 'react';
import { FakeEventConfig } from '@templates/FakeEventConfig';
import { FakeEventWidgetConfigData } from '@templates/FakeEventConfig/types';
import { CustomEventConfig } from '@templates/CustomEventConfig';
import { CustomEventWidgetConfigData } from '@templates/CustomEventConfig/types';

type ConfigTypes = CustomEventWidgetConfigData | FakeEventWidgetConfigData;

interface ConfigComponentProps {
  id: string;
  name: string;
  type_id: string;
  type: {
    id: string;
    slug: string;
    name: string;
    description: string;
  };
  config: ConfigTypes;
}

interface WidgetComponents {
  [key: string]: (props: ConfigComponentProps) => JSX.Element;
}

const widgetComponents: WidgetComponents = {
  'custom-event-list': CustomEventConfig,
  'event-list': FakeEventConfig,
};

export const useWidgetComponentSelector = () => {
  const getConfigComponent = (configProps: ConfigComponentProps) => {
    const Component = widgetComponents[configProps.type.slug];
    if (!Component) return null;
    return React.createElement(Component, { ...configProps }, null);
  };

  return {
    getConfigComponent,
  };
};
