import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useToastContext } from '@contexts/ToastContext';
import { api } from '@services/userManagementApi';
import { queryClient } from '@services/queryClient';

interface UpdateWorkspaceData {
  name: string;
}
interface WorkspaceData {
  data: UpdateWorkspaceData;
  workspace_id: string;
}

export async function updateWorkspaces({
  data,
  workspace_id,
}: WorkspaceData): Promise<void> {
  await api.put(`/v1/workspaces/${workspace_id}`, data);
}

export const useMutationUpdateWorkspaces = () => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async ({ data, workspace_id }: WorkspaceData) => {
      await updateWorkspaces({ data, workspace_id });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['workspaces']);
        dispatchToast({
          message: t('success_update_dashboards'),
          type: 'success',
        });
      },
      onError: () => {
        dispatchToast({
          message: t('error_update_dashboards'),
          type: 'error',
        });
      },
    },
  );
};
