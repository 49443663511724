import { IconButton } from '@atoms/IconButton';
import { H6 } from '@nucleus/Typography';
import { useTheme } from 'styled-components/native';
import { Container } from './styles';
import backIcon from '../../../../assets/icons/back.svg';

interface WorkspaceTabTitleProps {
  title: string;
  onBackPress: () => void;
}

export const WorkspaceTabTitle = ({
  title,
  onBackPress,
}: WorkspaceTabTitleProps) => {
  const theme = useTheme();
  return (
    <Container>
      <IconButton
        icon={backIcon}
        onPress={onBackPress}
        fill={theme.white}
        width={12}
        height={19}
        viewBox="0 0 8 13"
      />
      <H6 style={{ marginRight: 20, marginLeft: 8 }}>{title}</H6>
    </Container>
  );
};
