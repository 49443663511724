/* eslint-disable react/jsx-no-useless-fragment */
import { useTheme } from 'styled-components/native';
import { AnalyticsTabsMainCouranteFlowRatioRatioBarItemValue } from '@molecules/AnalyticsTabsMainCouranteFlowRatioRatioBarItemValue';
import { useQueryMainCouranteFlowRatio } from '@services/hooks/useQueryMainCouranteFlowRatio';
import { Container, RatioContainer } from './styles';

interface SelectActionData {
  color: string;
  action_type: {
    value: string;
    label: string;
  };
}

interface AnalyticsTabsMainCouranteFlowRatioRatioBarProps {
  main_courante_id: string;
  listOfInputs: SelectActionData[];
  listOfOutputs: SelectActionData[];
}
export const AnalyticsTabsMainCouranteFlowRatioRatioBar = ({
  main_courante_id,
  listOfInputs,
  listOfOutputs,
}: AnalyticsTabsMainCouranteFlowRatioRatioBarProps) => {
  const theme = useTheme();
  const { data } = useQueryMainCouranteFlowRatio({
    main_courante_id,
    input_ids: listOfInputs.map(input => input.action_type.value),
    output_ids: listOfOutputs.map(output => output.action_type.value),
  });

  function getItemColor(action_id: number, list: SelectActionData[]) {
    const color = list.find(
      input => input.action_type.value === String(action_id),
    )?.color;

    return color || theme.blue;
  }
  return (
    <>
      {data && (
        <Container>
          {data.inputs.map(input => (
            <AnalyticsTabsMainCouranteFlowRatioRatioBarItemValue
              key={`input_${input.id}`}
              title={input.name}
              value={input.total}
              color={getItemColor(input.id, listOfInputs)}
            />
          ))}
          {data.outputs.map(output => (
            <AnalyticsTabsMainCouranteFlowRatioRatioBarItemValue
              key={`output_${output.id}`}
              title={output.name}
              value={output.total}
              color={getItemColor(output.id, listOfOutputs)}
            />
          ))}
          <RatioContainer>
            <AnalyticsTabsMainCouranteFlowRatioRatioBarItemValue
              title="Ratio"
              value={data.ratio}
              color={theme.red}
              titleColor={theme.white}
              isRatio
            />
          </RatioContainer>
        </Container>
      )}
    </>
  );
};
