import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingModal } from '@atoms/LoadingModal';

import { WorkspacesAreaAddButton } from '@molecules/WorkspacesArea/AddButton';
import { ConfirmCancelModal } from '@molecules/ConfirmCancelModal';
import { NameModal } from '@molecules/NameModal';

import { WorkspacesAreaGrid } from '@organisms/WorkspacesArea/Grid';
import { WorkspacesAreaSortBy } from '@organisms/WorkspacesArea/SortBy';
import { WorkspacesAreaCard } from '@organisms/WorkspacesArea/Card';

import {
  useQueryWorkpaces,
  Workspace,
} from '@services/hooks/useQueryWorkpaces';
import { useMutationCreateWorkspace } from '@services/hooks/useMutationCreateWorkspace';
import { useNavigation } from '@react-navigation/native';
import { useMutationDeleteWorkspace } from '@services/hooks/useMutationDeleteWorkspace';
import { useMutationUpdateWorkspaces } from '@services/hooks/useMutationUpdateWorkspace';

import { Container } from './styles';

export const WorkspacesArea = () => {
  const { navigate } = useNavigation();
  const { t } = useTranslation();
  const [deleteWorkspaceId, setDeleteWorkspaceId] = useState<string>();
  const deleteWorkspaceMutation = useMutationDeleteWorkspace();
  const updateWorkspaceMutation = useMutationUpdateWorkspaces();
  const workspacesQuery = useQueryWorkpaces();
  const createWorkspaceMutation = useMutationCreateWorkspace();

  const [createNewWorkspaceModalOpen, setCreateNewWorkspaceModalOpen] =
    useState(false);

  const [sortType, setSortType] = useState<'newest' | 'oldest' | 'name'>(
    'newest',
  );

  const handleDeleteWorkspace = async () => {
    if (deleteWorkspaceId) {
      await deleteWorkspaceMutation.mutateAsync(deleteWorkspaceId);
      setDeleteWorkspaceId(undefined);
    }
  };

  const handleRenameWorkspace = async (
    newName: string,
    workspaceId: string,
  ) => {
    await updateWorkspaceMutation.mutateAsync({
      workspace_id: workspaceId,
      data: { name: newName },
    });
  };

  async function handleCreateNewWorkspace(newWorkspaceName: string) {
    const workspace = await createWorkspaceMutation.mutateAsync({
      name: newWorkspaceName,
    });
    setCreateNewWorkspaceModalOpen(false);
    navigate('Dashboard', { id: workspace.id });
  }

  const sortedData = useMemo(() => {
    if (!workspacesQuery.data) return undefined;
    return workspacesQuery.data.sort((a: Workspace, b: Workspace) => {
      if (sortType === 'name') {
        return a.name.localeCompare(b.name);
      }
      if (sortType === 'oldest') {
        return (
          new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
        );
      }
      return (
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      );
    });
  }, [sortType, workspacesQuery.data]);

  return (
    <Container>
      <WorkspacesAreaAddButton
        onAddPress={() => setCreateNewWorkspaceModalOpen(true)}
      />
      <WorkspacesAreaSortBy
        onPressName={() => setSortType('name')}
        onPressNewest={() => setSortType('newest')}
        onPressOldest={() => setSortType('oldest')}
      />
      <WorkspacesAreaGrid>
        {sortedData &&
          sortedData.map(workspace => (
            <WorkspacesAreaCard
              onRename={newName => handleRenameWorkspace(newName, workspace.id)}
              onDelete={() => setDeleteWorkspaceId(workspace.id)}
              onPress={() => navigate('Dashboard', { id: workspace.id })}
              name={workspace.name}
              userId={workspace.user_id}
              formatedDate={workspace.formatted_updated_at}
            />
          ))}
      </WorkspacesAreaGrid>
      {createNewWorkspaceModalOpen && (
        <NameModal
          title={t('name_your_dashboard')}
          isVisible={createNewWorkspaceModalOpen}
          onCancel={() => setCreateNewWorkspaceModalOpen(false)}
          onConfirm={handleCreateNewWorkspace}
          isCreating={createWorkspaceMutation.isLoading}
        />
      )}
      {!!deleteWorkspaceId && (
        <ConfirmCancelModal
          title={t('delete_dashboard_title')}
          isVisible={!!deleteWorkspaceId}
          onConfirm={handleDeleteWorkspace}
          onCancel={() => setDeleteWorkspaceId(undefined)}
        />
      )}
      {(updateWorkspaceMutation.isLoading ||
        deleteWorkspaceMutation.isLoading) && (
        <LoadingModal
          visible={
            updateWorkspaceMutation.isLoading ||
            deleteWorkspaceMutation.isLoading
          }
        />
      )}
    </Container>
  );
};
