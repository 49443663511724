import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  height: calc(100vh - 60px);
  padding: 16px;
`;

export const ContentContainer = styled.View`
  width: 100%;
  margin-top: 32px;
  height: calc(100% - 140px);
  padding: 16px;
  border-radius: 8px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.purpleBlue};
  flex-direction: row;
`;

export const SideContainer = styled.View`
  width: 50%;
  height: 100%;
  padding: 8px;
  justify-content: space-between;
`;

export const SelectedInputContainer = styled.View`
  width: 100%;
  height: 25%;
  border-radius: 8px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.purpleBlue};
`;

export const ButtonsContainer = styled.View`
  width: 100%;
  height: 70px;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
`;
