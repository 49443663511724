import styled from 'styled-components/native';
import { Button } from '@atoms/Button';
import { Dimensions } from 'react-native';

export const Container = styled.View`
  margin-top: 16px;
  max-width: 280px;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  height: 100%;
`;

export const FilterContainer = styled.View`
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 168px);
`;

export const Stack = styled.View`
  height: 32px;
  width: 100%;
`;

export const ClearButton = styled(Button)`
  height: ${() => {
    const { width } = Dimensions.get('window');
    if (width < 1300) {
      return '40px';
    }
    return '48px';
  }};
`;
