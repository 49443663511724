/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/no-unused-prop-types */
import { useState, useMemo } from 'react';

import { CustomEventConfigHeader } from '@organisms/CustomEventConfig/Header';
import { Button } from '@atoms/Button';
import {
  useMutationUpdateWidgetConfig,
  UpdateWidgetConfigData,
} from '@services/hooks/useMutationUpdateWidgetConfig';

import { LoadingModal } from '@atoms/LoadingModal';
import { useTranslation } from 'react-i18next';
import { FakeEventWidgetConfigData, baseName } from './types';
import { Container, ContentContainer, ButtonsContainer } from './styles';

interface FakeEventWidgetConfigProps {
  id: string;
  name: string;
  type_id: string;
  type: {
    id: string;
    slug: string;
    name: string;
    description: string;
  };
  config: FakeEventWidgetConfigData;
}

export const FakeEventConfig = ({
  id,
  name,
  config,
}: FakeEventWidgetConfigProps) => {
  const { t } = useTranslation();
  const [configName, setConfigName] = useState(name);

  const isNew = useMemo(() => name === baseName, [name]);

  const updateWidgetConfigMutation = useMutationUpdateWidgetConfig({
    widget_config_id: id,
  });

  const saveConfig = async (updatedData: UpdateWidgetConfigData) => {
    await updateWidgetConfigMutation.mutateAsync({
      name: updatedData.name,
      config: updatedData.config,
    });
  };

  const onFinish = async () => {
    await saveConfig({ name: configName, config });
    // the react navigate goBack isn't working on web
    window.history.back();
  };

  return (
    <Container>
      <CustomEventConfigHeader
        title={configName}
        onUpdateTitle={setConfigName}
        // steps={[t('custom_event_template'), t('action_template')]}
        // stepIndex={stepIndex}
      />
      <ContentContainer />

      <ButtonsContainer>
        <Button
          loading={updateWidgetConfigMutation.isLoading}
          onPress={onFinish}
        >
          {isNew ? t('finish') : t('update')}
        </Button>
      </ButtonsContainer>

      {updateWidgetConfigMutation.isLoading && <LoadingModal />}
    </Container>
  );
};
