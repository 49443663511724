/* eslint-disable no-return-await */
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useToastContext } from '@contexts/ToastContext';
import { queryClient } from '@services/queryClient';
import { Workspace } from '@services/hooks/useQueryWorkspace';
import { api } from '@services/userManagementApi';

interface WorkspaceData {
  name: string;
}

export async function createWorkspace(data: WorkspaceData): Promise<Workspace> {
  try {
    const response = await api.post(`/v1/workspaces`, data);
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export const useMutationCreateWorkspace = () => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async ({ name }: WorkspaceData) => {
      return await createWorkspace({ name });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['workspaces']);
        dispatchToast({
          message: t('success_create_dashboard'),
          type: 'success',
        });
      },
      onError: () => {
        dispatchToast({
          message: t('error_create_dashboard'),
          type: 'error',
        });
      },
    },
  );
};
