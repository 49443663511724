import styled from 'styled-components/native';
import { Platform } from 'react-native';

export const Container = styled.View`
  width: 100%;
  max-width: 520px;
  padding: 8px;
  justify-content: space-between;
  flex-direction: row;
  height: 32px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.black};
`;

export const IconsContainer = styled.View`
  flex-direction: row;
  width: 20px;
`;

export const InputText = styled.TextInput`
  width: calc(100% - 20px);
  color: ${({ theme }) => theme.white};
  font-size: ${Platform.OS === 'web' ? '1.4rem' : '14px'};
  line-height: ${Platform.OS === 'web' ? '2.0rem' : '20px'};
  font-weight: 400;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_400Regular'};
  letter-spacing: ${Platform.OS === 'web' ? '-0.044rem' : '-0.44px'};
`;
