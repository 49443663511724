/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/destructuring-assignment */
import { useState, useMemo } from 'react';
import { H6 } from '@nucleus/Typography';
import { CustomEventConfigHeader } from '@organisms/CustomEventConfig/Header';
import { CustomEventConfigInputSelector } from '@organisms/CustomEventConfig/InputSelector';
import { CustomEventConfigPreview } from '@organisms/CustomEventConfig/Preview';
import { Button } from '@atoms/Button';
import {
  useMutationUpdateWidgetConfig,
  UpdateWidgetConfigData,
} from '@services/hooks/useMutationUpdateWidgetConfig';
import { NameModal } from '@molecules/NameModal';
import { LoadingModal } from '@atoms/LoadingModal';
import { useTranslation } from 'react-i18next';
import { CustomEventWidgetConfigData, baseName } from './types';
import {
  Container,
  ContentContainer,
  SideContainer,
  SelectedInputContainer,
  ButtonsContainer,
} from './styles';

interface CustomEventWidgetConfigProps {
  id: string;
  name: string;
  type_id: string;
  type: {
    id: string;
    slug: string;
    name: string;
    description: string;
  };
  config: CustomEventWidgetConfigData;
}

export const CustomEventConfig = ({
  id,
  name,
  config,
}: CustomEventWidgetConfigProps) => {
  const { t } = useTranslation();
  const [stepIndex, setStepIndex] = useState(1);
  const [isNameModalOpen, setIsNameModalOpen] = useState(false);

  const isNew = useMemo(() => name === baseName, [name]);

  const updateWidgetConfigMutation = useMutationUpdateWidgetConfig({
    widget_config_id: id,
  });

  const saveConfig = async (updatedData: UpdateWidgetConfigData) => {
    await updateWidgetConfigMutation.mutateAsync({
      name: updatedData.name,
      config: updatedData.config,
    });
    if (isNameModalOpen) {
      setIsNameModalOpen(false);
    }
  };

  const onUpdateConfig = async (updatedData: UpdateWidgetConfigData) => {
    await saveConfig(updatedData);
    setIsNameModalOpen(false);
    // the react navigate goBack isn't working on web
    window.history.back();
  };

  const onFinish = async () => {
    if (isNew) {
      setIsNameModalOpen(true);
      return;
    }
    await saveConfig({ name, config });
    // the react navigate goBack isn't working on web
    window.history.back();
  };

  return (
    <Container>
      <CustomEventConfigHeader
        steps={[t('custom_event_template'), t('action_template')]}
        stepIndex={stepIndex}
      />
      <ContentContainer>
        <SideContainer>
          <H6 style={{ marginBottom: 8 }}>{t('select_inputs_actions')}</H6>
          <CustomEventConfigInputSelector onNewInput={() => {}} />
          <H6 style={{ marginTop: 8, marginBottom: 8 }}>
            {t('inputs_selected')}
          </H6>
          <SelectedInputContainer />
        </SideContainer>
        <SideContainer>
          <CustomEventConfigPreview />
        </SideContainer>
      </ContentContainer>
      {stepIndex === 1 ? (
        <ButtonsContainer>
          <Button onPress={() => setStepIndex(2)}>{t('next')}</Button>
        </ButtonsContainer>
      ) : (
        <ButtonsContainer>
          <Button style={{ marginRight: 16 }} onPress={() => setStepIndex(1)}>
            {t('back')}
          </Button>
          <Button
            loading={updateWidgetConfigMutation.isLoading}
            onPress={onFinish}
          >
            {isNew ? t('finish') : t('update')}
          </Button>
        </ButtonsContainer>
      )}
      {isNameModalOpen && (
        <NameModal
          title={t('name_your_widget_config')}
          placeholder={t('name_of_the_widget_config')}
          isVisible={isNameModalOpen}
          onCancel={() => setIsNameModalOpen(false)}
          onConfirm={newName => onUpdateConfig({ name: newName, config })}
        />
      )}
      {updateWidgetConfigMutation.isLoading && <LoadingModal />}
    </Container>
  );
};
