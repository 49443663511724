/* eslint-disable @typescript-eslint/no-explicit-any */
interface Location {
  bbox: any;
  place_name: string;
  geometry: any;
}

export interface FakeEventWidgetData {
  filters: {
    categories: number[];
    any_terms: string[];
    all_terms: string[];
    exclude_terms: string[];
    location?: Location;
    relevancies: number[];
    start_date?: Date;
    end_date?: Date;
    mainCourante: string[];
    zones: string[];
  };
}

export interface FakeEventWidgetInitialData {
  grid_height: number;
  grid_width: number;
  data: FakeEventWidgetData;
}

export const fakeEventWidgetInitialData: FakeEventWidgetInitialData = {
  grid_height: 6,
  grid_width: 4,
  data: {
    filters: {
      categories: [],
      any_terms: [],
      all_terms: [],
      exclude_terms: [],
      location: undefined,
      relevancies: [],
      start_date: undefined,
      end_date: undefined,
      mainCourante: [],
      zones: [],
    },
  },
};
