import styled from 'styled-components/native';
import { Body1 } from '@nucleus/Typography';

interface ButtonProps {
  isSelected?: boolean;
}

export const Button = styled.TouchableOpacity<ButtonProps>`
  border-bottom-color: ${({ theme }) => theme.blue};
  border-bottom-width: ${({ isSelected }) => (isSelected ? '2px' : 0)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  margin-right: 16px;
  height: 48px;
`;

interface ButtonTextProps {
  isHovered?: boolean;
}

export const ButtonText = styled(Body1)<ButtonTextProps>`
  color: ${({ isHovered, theme }) =>
    isHovered ? theme.purpleBlue : theme.white};
`;
