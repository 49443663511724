// import { useTranslation } from 'react-i18next';
import { H6, Body1, Body2 } from '@nucleus/Typography';
import { Input } from '@atoms/Workspace/Input';
import { useAuthContext } from '@contexts/AuthContext';
import { Container, UserDataContainer, RoleDataContainer } from './styles';

const roleMap: { [key: string]: string } = {
  'workspaces.service': 'Workspace User',
  'system.admin': 'Admin',
  'challenge.user': 'Challenge User',
};

export const ProfileMyAccount = () => {
  const { user } = useAuthContext();

  return (
    <Container>
      <UserDataContainer>
        <H6>Personal Information</H6>
        <Input
          label="name"
          value={user?.name}
          editable={false}
          style={{ marginTop: 48 }}
        />
        <Input
          label="email"
          value={user?.email}
          editable={false}
          style={{ marginTop: 16 }}
        />
      </UserDataContainer>
      <RoleDataContainer>
        <H6>Role</H6>
        <Body1 style={{ fontWeight: 'bold', marginTop: 32 }}>
          {`You are registred as:  ${user?.roles
            .map(role => roleMap[role])
            .join(',')}`}
        </Body1>
        <Body2 style={{ marginTop: 16 }}>
          If you lack access to features or think you should have another role,
        </Body2>
      </RoleDataContainer>
    </Container>
  );
};
