import { WorkspaceLayout } from '@templates/WorkspaceLayout';
import { ProfileMenu } from '@organisms/Profile/Menu';
import { ProfileMyAccount } from '@organisms/Profile/MyAccount';
import { H5 } from '@nucleus/Typography';
import { Container, ContentContainer } from './styles.web';

export const Profile = () => {
  return (
    <WorkspaceLayout>
      <Container>
        <H5 style={{ marginLeft: 16 }}>Profile Settings</H5>
        <ContentContainer>
          <ProfileMenu />
          <ProfileMyAccount />
        </ContentContainer>
      </Container>
    </WorkspaceLayout>
  );
};
