import { useEffect, RefObject, useCallback } from 'react';
import DayPicker from 'react-day-picker';
import { NativeMethods } from 'react-native';

interface UseOutsideClickProps {
  componentRef: RefObject<HTMLElement | NativeMethods | DayPicker>;
  yOffset: number;
  onOutsideClick: () => void;
}

interface ClickPoint {
  x: number;
  y: number;
}

interface ComponentArea {
  x: number;
  y: number;
  width: number;
  height: number;
}

export const useOutsideClick = ({
  componentRef,
  yOffset,
  onOutsideClick,
}: UseOutsideClickProps) => {
  const outsideClicked = useCallback(
    (clickPoint: ClickPoint, componentArea: ComponentArea) => {
      if (
        !(
          clickPoint.x > componentArea.x &&
          clickPoint.x < componentArea.x + componentArea.width &&
          clickPoint.y > componentArea.y - yOffset &&
          clickPoint.y < componentArea.y + componentArea.height
        )
      ) {
        return true;
      }
      return false;
    },
    [yOffset],
  );

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const component =
        componentRef?.current instanceof DayPicker
          ? componentRef.current?.dayPicker
          : componentRef?.current;

      if (!component) return;

      if (component instanceof HTMLElement) {
        const { x, y, width, height } = component.getBoundingClientRect();

        const clickedOut = outsideClicked(
          { x: event.x, y: event.y },
          { x, y, width, height },
        );

        if (clickedOut) {
          onOutsideClick();
        }
      } else {
        component.measureInWindow((x, y, width, height) => {
          const clickedOut = outsideClicked(
            { x: event.x, y: event.y },
            { x, y, width, height },
          );

          if (clickedOut) {
            onOutsideClick();
          }
        });
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [componentRef, yOffset, onOutsideClick, outsideClicked]);
};
