/* eslint-disable @typescript-eslint/no-empty-interface */
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useToastContext } from '@contexts/ToastContext';
import { api } from '@services/userManagementApi';

export interface UpdateWidgetConfigData {
  name?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config?: any;
}

interface UpdateWidgetConfig {
  data: UpdateWidgetConfigData;
  widget_config_id: string;
}

interface UpdateWidgetConfigMutation {
  widget_config_id: string;
}

export async function updateWidgets({
  data,
  widget_config_id,
}: UpdateWidgetConfig): Promise<void> {
  await api.put(`/v1/widget/configs/${widget_config_id}`, data);
}

export const useMutationUpdateWidgetConfig = ({
  widget_config_id,
}: UpdateWidgetConfigMutation) => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async (data: UpdateWidgetConfigData) => {
      await updateWidgets({ data, widget_config_id });
    },
    {
      onSuccess: async () => {
        dispatchToast({
          message: t('success_update_widget_config'),
          type: 'success',
        });
      },
      onError: () => {
        dispatchToast({
          message: t('error_update_widget_config'),
          type: 'error',
        });
      },
    },
  );
};
