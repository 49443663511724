import styled from 'styled-components/native';
import { Platform } from 'react-native';

interface Props {
  color: 'primary' | 'secondary';
}

export const Container = styled.View<Props>`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background: ${({ theme, color }) =>
    color === 'primary' ? theme.blue : theme.purpleBlue};
  font-size: 12px;
  color: ${({ theme, color }) =>
    color === 'primary' ? theme.white : theme.black};
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`;

export const Text = styled.Text<Props>`
  color: ${({ theme, color }) =>
    color === 'primary' ? theme.white : theme.black};
  font-size: ${Platform.OS === 'web' ? '1.8rem' : '18px'};
  line-height: ${Platform.OS === 'web' ? '3.2rem' : '32px'};
  font-weight: 700;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_700Bold'};
  letter-spacing: ${Platform.OS === 'web' ? '-0.015rem' : '-0.15px'};
`;
