import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { H5 } from '@nucleus/Typography';

import { Modal } from '@atoms/Modal';
import { Input } from '@atoms/Workspace/Input';

import { Container, ButtonsContainer, Button, FormContainer } from './styles';

interface NameModalProps {
  oldName?: string;
  title: string;
  placeholder?: string;
  isVisible?: boolean;
  isCreating?: boolean;
  onConfirm: (newWorkspaceName: string) => void;
  onCancel: () => void;
}

export const NameModal = ({
  oldName,
  title,
  placeholder,
  isVisible,
  onConfirm,
  onCancel,
  isCreating,
}: NameModalProps) => {
  const { t } = useTranslation();
  const [name, setName] = useState(oldName || '');
  const [inputError, setInputError] = useState<string>();

  const handleConfirm = () => {
    setInputError(undefined);
    if (name.length === 0) {
      setInputError(t('name_required'));
      return;
    }

    onConfirm(name);
  };

  return (
    <Modal visible={isVisible}>
      <Container>
        <H5>{title}</H5>
        <FormContainer>
          <Input
            value={name}
            onChangeText={setName}
            placeholder={placeholder || ''}
            style={{ maxWidth: 268 }}
            error={inputError}
          />
        </FormContainer>
        <ButtonsContainer>
          <Button outline onPress={onCancel} style={{ marginRight: 16 }}>
            {t('modal_cancel')}
          </Button>
          <Button loading={isCreating} onPress={handleConfirm}>
            {t('modal_confirm')}
          </Button>
        </ButtonsContainer>
      </Container>
    </Modal>
  );
};
