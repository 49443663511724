import { ReactNode } from 'react';
import { H6, Body1 } from '@nucleus/Typography';
import { IconButton } from '@atoms/IconButton';
import { useWindowDimensions } from 'react-native';
import { Container, Header } from './styles';
import conseIcon from '../../../assets/icons/close.svg';

interface WidgetSidePanelProps {
  onClose: () => void;
  title: string;
  children: ReactNode;
}

export const WidgetSidePanel = ({
  onClose,
  title,
  children,
}: WidgetSidePanelProps) => {
  const { width } = useWindowDimensions();
  return (
    <Container>
      <Header>
        {width < 1500 ? (
          <Body1 style={{ fontWeight: '800' }}>{title}</Body1>
        ) : (
          <H6>{title}</H6>
        )}
        <IconButton
          icon={conseIcon}
          fill="white"
          width={24}
          height={24}
          viewBox="0 0 30 30"
          onPress={onClose}
        />
      </Header>
      {children}
    </Container>
  );
};
