/* eslint-disable @typescript-eslint/no-empty-function */
import { useMemo } from 'react';
import { IWidget, useWidgetGridContext } from '@contexts/WidgetGridContext';
import { WidgetSidePanel } from '@molecules/WidgetSidePanel';
// import { useMutationUpdateWidgets } from '@services/hooks/useMutationUpdateWidgets';
import { CustomEventWidgetData } from '@templates/CustomEventWidget/types';
import { CustomEventWidgetConfigData } from '@templates/CustomEventConfig/types';

interface WidgetExampleSideBarProps {
  widgetData: IWidget<CustomEventWidgetData, CustomEventWidgetConfigData>;
}

export const CustomEventWidgetSideBar = ({
  widgetData,
}: WidgetExampleSideBarProps) => {
  const { setSidePanelData, /* updateWidgetData, */ widgets } =
    useWidgetGridContext();
  // const mutationUpdateWidgets = useMutationUpdateWidgets();
  const widget:
    | IWidget<CustomEventWidgetData, CustomEventWidgetConfigData>
    | undefined = useMemo(
    () => widgets.find(widgetItem => widgetItem.id === widgetData.id),
    [widgets, widgetData.id],
  );
  /*
  const onUpdateData = async () => {
    const newData: CustomEventWidgetData = {
      custom_event: [],
    };

    await mutationUpdateWidgets.mutateAsync([
      {
        id: widgetData.id,
        data: { ...newData },
      },
    ]);

    updateWidgetData<CustomEventWidgetData>(widgetData.id, {
      data: { ...newData },
    });
  };
*/
  return (
    <WidgetSidePanel
      title="Custom Event Filters"
      onClose={() => setSidePanelData({ widget: undefined, isOpened: false })}
    >
      {widget && <p>{widget.id}</p>}
    </WidgetSidePanel>
  );
};
