import styled from 'styled-components/native';
import { Caption } from '@nucleus/Typography';

export const Container = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

interface StepCircleProps {
  selected: boolean;
}

export const CircleLegend = styled(Caption)`
  position: absolute;
  top: 54;
  border-width: 1px;
  min-width: 150px;
  left: -50px;
  text-align: center;
`;

export const StepCircleContainer = styled.View`
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const StepCircle = styled.View<StepCircleProps>`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  background-color: ${({ selected, theme }) =>
    selected ? theme.purpleBlue : 'transparent'};
  border-color: ${({ theme }) => theme.purpleBlue};
  border-width: 2px;
`;

export const SpaceLine = styled.View`
  width: 120px;
  height: 2px;
  background-color: ${({ theme }) => theme.purpleBlue};
`;
