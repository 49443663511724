import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.purpleBlue};
  padding: 16px;
`;
