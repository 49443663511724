import { useRoute, RouteProp } from '@react-navigation/native';
import { useQueryWidgetConfig } from '@services/hooks/useQueryWidgetConfig';
import { WorkspaceLayout } from '@templates/WorkspaceLayout';
import { Spinner } from '@atoms/Spinner';
import { useWidgetComponentSelector } from '../../hooks/useConfigComponentSelector';
import { LoadingContainer } from './styles';

type ParamList = {
  WidgetConfig: {
    id: string;
  };
};

export function WidgetConfig() {
  const route = useRoute<RouteProp<ParamList, 'WidgetConfig'>>();
  const { getConfigComponent } = useWidgetComponentSelector();
  const widgetConfigQuery = useQueryWidgetConfig({
    widget_config_id: route.params.id,
  });
  return (
    <WorkspaceLayout>
      {widgetConfigQuery.isFetching ? (
        <LoadingContainer>
          <Spinner size="large" />
        </LoadingContainer>
      ) : (
        widgetConfigQuery.data && getConfigComponent(widgetConfigQuery.data)
      )}
    </WorkspaceLayout>
  );
}
