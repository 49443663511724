import styled from 'styled-components/native';
import { transparentize } from 'polished';

export const Container = styled.View`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => transparentize(0.3, theme.black)};
  margin: auto;
  justify-content: center;
  align-items: center;
`;
