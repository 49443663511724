import styled from 'styled-components/native';
import { Button as ButtonComponent } from '@atoms/Button';

export const Container = styled.View`
  max-width: 516px;
  width: 90%;
  max-height: 198px;
  background-color: ${({ theme }) => theme.blueishBlack};
  border-radius: 8px;
  padding: 16px;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const FormContainer = styled.View`
  margin-top:16px
  width: 100%;
`;

export const ButtonsContainer = styled.View`
  margin-top:20px
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;

`;

export const Button = styled(ButtonComponent)`
  width: 137px;
  height: 39px;
`;
