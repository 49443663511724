import styled, { css } from 'styled-components/native';
import { transparentize, darken } from 'polished';
import { Body2 } from '@nucleus/Typography';

interface ContainerProps {
  isHovered?: boolean;
  isSelected?: boolean;
}

export const Container = styled.TouchableOpacity<ContainerProps>`
  width: 95%;
  max-width: 300px;
  height: 48px;
  background-color: ${({ theme, isHovered }) =>
    isHovered ? darken(0.05, theme.blue) : theme.blue};
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.purpleBlue};

  ${({ isHovered, isSelected }) => {
    if (isHovered) {
      return css`
        background-color: ${({ theme }) =>
          transparentize(0.9, theme.purpleBlue)};
      `;
    }
    if (isSelected) {
      return css`
        background-color: ${({ theme }) => theme.purpleBlue};
      `;
    }
    return css`
      background-color: transparent;
    `;
  }}
`;

export const ButtonText = styled(Body2)<ContainerProps>`
  color: ${({ theme }) => {
    return theme.white;
  }};
`;
