import { H6, Body1 } from '@nucleus/Typography';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@atoms/IconButton';
import { WorkspacePageAddNewWidgetModalList } from '@atoms/WorkspacePageAddNewWidgetModal/List';

import { WidgetType } from '@services/hooks/useQueryWidgetTypes';

import backIcon from '../../../../assets/icons/back.svg';
import { TitleContainer, WidgetTypesListContainer } from './styles';

interface WorkspacePageAddNewWidgetModalStep2Props {
  widgetTypes: WidgetType[];
  parentWidgetName: string;
  onBackPress?: () => void;
  onSelectType: (widgetType: WidgetType) => void;
}

export const WorkspacePageAddNewWidgetModalStep2 = ({
  widgetTypes,
  onSelectType,
  parentWidgetName,
  onBackPress,
}: WorkspacePageAddNewWidgetModalStep2Props) => {
  const { t } = useTranslation();
  return (
    <>
      <TitleContainer>
        <IconButton icon={backIcon} onPress={onBackPress} fill="#fff" />
        <Body1 style={{ marginLeft: 8 }}>{parentWidgetName}</Body1>
      </TitleContainer>
      <H6 style={{ marginTop: 8 }}>
        {t('choose_widget_type').replace('$parent$', parentWidgetName)}
      </H6>
      <WidgetTypesListContainer>
        {widgetTypes.map(widgetType => (
          <WorkspacePageAddNewWidgetModalList
            name={widgetType.name}
            description={widgetType.description}
            onPress={() => onSelectType(widgetType)}
          />
        ))}
      </WidgetTypesListContainer>
    </>
  );
};
