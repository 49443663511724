import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components/native';
import { Caption } from '@nucleus/Typography';
import { Spinner } from '@atoms/Spinner';
import { Board } from '@atoms/Board';
import { Badge } from '@atoms/Badge';
import { PreviewItem } from '@atoms/PreviewItem';
import { usePreviewContext } from '@contexts/PreviewContext';
import { Media } from '../../../services/hooks/useQueryEventInfo';
import { BoardContent } from './styles';

interface EventInfoOverviewMediaBoardProps {
  media?: Media;
  isLoading: boolean;
}

export const EventInfoOverviewMediaBoard = ({
  media,
  isLoading,
}: EventInfoOverviewMediaBoardProps) => {
  const { openPreview } = usePreviewContext();
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Board style={{ height: 100 }}>
      <Caption style={{ marginLeft: 12 }}>{t('photos_and_videos')}</Caption>
      {isLoading ? (
        <Spinner color={theme.purple} size={20} />
      ) : (
        media && (
          <>
            <Badge
              text={String(media.photos.length + media.videos.length)}
              color={theme.purple}
              style={{ position: 'absolute', right: -4, top: -4 }}
            />
            <BoardContent>
              {media?.photos.map(photo => (
                <PreviewItem
                  openPreview={() =>
                    openPreview({
                      source: photo,
                      type: 'image',
                    })
                  }
                  style={{ width: 80, padding: 3 }}
                  key={`overview_${photo}`}
                  source={photo}
                  type="image"
                />
              ))}
              {media?.videos.map(video => (
                <PreviewItem
                  openPreview={() =>
                    openPreview({
                      source: video,
                      type: 'video',
                    })
                  }
                  style={{ width: 80, padding: 3 }}
                  key={`overview_${video}`}
                  source={video}
                  type="video"
                />
              ))}
            </BoardContent>
          </>
        )
      )}
    </Board>
  );
};
