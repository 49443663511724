import { useRef, ReactNode } from 'react';
import { useWidgetGridContext } from '@contexts/WidgetGridContext';
import { ResizableProps } from 'react-resizable';
import { ConnectDragSource } from 'react-dnd';

import { StyledWidgetContent, CustomResizable } from './styles';

interface WidgetContentProps extends ResizableProps {
  isDragPreview: boolean;
  isColliding: boolean;
  dragRef?: ConnectDragSource;
  children?: ReactNode;
}

export const WidgetContent = ({
  isDragPreview,
  width,
  height,
  isColliding,
  dragRef,
  children,
  ...rest
}: WidgetContentProps) => {
  const { isEditMode } = useWidgetGridContext();

  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <CustomResizable
      {...rest}
      handleSize={[10, 10]}
      width={width}
      height={height}
      isEditMode={isEditMode}
      resizeHandles={
        isEditMode ? ['se', 's', 'e', 'w', 'n', 'ne', 'nw', 'sw'] : []
      }
    >
      <StyledWidgetContent
        ref={contentRef}
        isEditMode={isEditMode}
        isColliding={isColliding}
        isDragPreview={isDragPreview}
        style={{ width, height }}
      >
        {!isDragPreview && (
          <div ref={isEditMode ? dragRef || null : null} className="content">
            {children}
          </div>
        )}
      </StyledWidgetContent>
    </CustomResizable>
  );
};
