export default {
  translation: {
    login: 'Login',
    password: 'Mot de passe ',
    stay_connected: 'Rester connecté',
    log_in: 'Connexion',
    forgot_password: 'Mot de passe oublié ?',
    login_placeholder: 'Mot de passe oublié ?',
    valid_field: 'Ce champ est requis',
    valid_email: 'Doit être un e-mail valide',
    events_list: 'Liste des événements',
    all: 'Tout',
    media: 'Médias',
    social_networks: 'Réseaux Sociaux',
    official_sources: 'Sources Officielles',
    last_updated: 'Dernière MAJ',
    modal_confirm: 'Valider',
    modal_cancel: 'Annuler',
    close_tab_modal_title: 'Êtes-vous sûr de vouloir fermer l’onglet ?',
    overview: 'Aperçu',
    details: 'Détails',
    actors_concerned: 'Acteurs concernés ',
    photos_and_videos: 'Photos et vidéos',
    additional_content: 'Contenus supplémentaires',
    more_details: 'Plus de détails',
    start: 'Début',
    end: 'Fin',
    cartographic_elements: 'Eléments cartographiques',
    basemaps: 'Fonds de carte',
    map_layers: 'Couches cartographiques',
    dark: 'Foncé',
    clear: 'Clair',
    satellite: 'Satellite',
    streets: 'Rues',
    landforms: 'Reliefs',
    borders: 'Frontières',
    movements: 'Mouvements',
    highway_06: 'Inforoutes 06',
    flood_zones: 'Zones inondables',
    clear_filters: 'Effacer les filtres',
    filters: 'Filtres',
    basics: 'Basiques',
    advanced: 'Avancés',
    event_type: "Type d'évènement",
    search: 'Rechercher',
    any_term: 'N’importe quelle terme',
    all_of_these_terms: 'Tous ces termes',
    exclude_these_terms: 'Exclure ces termes',
    date: 'Date',
    location: 'Localisation',
    in_progress: 'En cours',
    finished: 'Terminé',
    sector_location: 'Secteur',
    relevance: 'Pertinence',
    date_of_detection: 'Date de détection',
    start_date: 'Date de début',
    end_date: 'Date de fin',
    main_courante: 'Main Courante',
    zones_created: 'Zones crées',
    create_a_zone: 'Créer une zone',
    low: 'Faible',
    medium: 'Moyen',
    high: 'Élevé',
    email: 'E-mail',
    send_email: 'Envoyer un e-mail',
    confirm: 'Valider',
    cancel: 'Annuler',
    password_confirmation: 'Confirmation du mot de passe',
    save: 'Enregistrer',
    success_email_sent: 'E-mail envoyé, vérifiez votre boîte de réception',
    success_password_sent: 'Changement de mot de passe réussi',
    generic_request_error:
      "Erreur lors de la demande, réessayez s'il vous plait",
    step_1_create_main_courante: 'Etape 1 : Créer ou choisir sa main courante',
    create_new_main_courante: 'Créer une nouvelle main courante',
    name_of_main_courante: 'Nom de la main courante',
    main_courante_title: 'Titre de la main courante',
    step_2_create_main_courante: 'Etape 2 : Créer une main courante',
    hour: 'Heure',
    existing_address: 'Adresse existante',
    custom_address: 'Adresse personnalisée',
    shared_with: 'Partagé avec',
    priority: 'Priorité',
    more_information: 'Plus d’informations',
    click_to_add_media: 'Cliquez sur le bouton pour ajouter de nouveaux médias',
    step_2_create_action: 'Etape 2 : Créer une action',
    title_of_the_action: 'Titre de l’action',
    type_of_action: 'Type d’action',
    create_a_type: 'Créer un type',
    close_main_courante: 'Voulez-vous fermer cette main courante ?',
    yes: 'Oui',
    no: 'Non',
    selected_main_courante: 'Main courante selectionnée',
    create_action_type: 'Créer un type d’action',
    name_of_the_action: 'Nom de l’action',
    create_action_type_placeholder: 'Route barrée',
    Floods: 'Inondations',
    Wildfires: 'Feux de forêts',
    Earthquake: 'Risques sismiques',
    'Natural hazards': 'Risques naturels',
    All: 'Tout',
    Media: 'Médias',
    'Social networks': 'Réseaux Sociaux',
    'Official sources': 'Sources Officielles',
    want_rename_file: 'Voulez-vous le renommez ?',
    dynamic_data: 'Données dynamiques',
    historical_data: 'Données historiques',
    risk_statistics: ' Statistiques des risques',
    name: 'Nom',
    data: 'Données',
    unit: 'Unité',
    source: 'Source',
    my_documents: 'Mes documents',
    file_name: 'Nom du fichier',
    delete_document_confirmation:
      'Êtes vous sûr de vouloir supprimer ce document?',
    delete_document_error: 'Error lors du téléchargement du document',
    choose_team: 'Choisir une équipe',
    name_required: 'le nom est un champ obligatoire',
    priority_required: 'la priorité est un champ obligatoire',
    time_required: "l'heure est un champ obligatoire",
    date_required: 'la date est un champ obligatoire',
    description_required: 'la description est un champ obligatoire',
    location_required: "l'emplacement est un champ obligatoire",
    action_required: "l'action est un champ obligatoire",
    media_required: 'le média est un champ obligatoire',
    erro_updated_file: 'Error sur le fichier mis à jour',
    erro_delete_file: 'Error lors de la suppression du fichier',
    action_type_created: "Type d'action créée",
    error_create_action_type: "Erreur lors de la création du type d'action",
    error_created_document: 'Erreur sur le document créé',
    document_created: 'Document créé',
    document_updated: 'Document mis à jour',
    document_deleted: 'Document supprimé',
    main_courante_created: 'Main courante créée',
    error_create_main_courante:
      'Erreur lors de la création de la main courante',
    error_create_tab: "Erreur lors de la création de l'onglet",
    error_create_zone: 'Erreur lors de la création de la zone',
    type_address: 'Adresse',
    type_main_courante_name: 'Nom de la main courante',
    type_main_courante_title: 'Titre de la main courante',
    type_action_title: "Titre de l'action",
    type_additional_information: "Plus d'informations",
    zone_name: 'Nom de la zone',
    content_text_error: `Désolée, le contenu ne peut être chargé pour le moments, cliquez sur "Plus de détails" pour accéder à la source`,
    opened: 'Ouverte',
    closed: 'Fermé',
    openeds: 'Ouvertes',
    closeds: 'Fermées',
    number_of_actions: 'Nombre d’actions',
    the_actions: 'Les actions',
    details_of_the_action: 'Détail de l’action',
    to_choose_existing_main_courante: 'Choisir une main courante existante',
    choose_existing_main_courante: 'Choisissez une main courante existante',
    want_to_be_able_to: 'Je veux pouvoir :',
    give_new_main_courante_title: 'Donnez un titre à la nouvelle main courante',
    choose_team_to_share_new_main_courante:
      'Choisissez une équipe avec qui vous allez partager la nouvelle main courante',
    team_name: 'Nom de l’équipe',
    find_the_location: 'Trouver la localisation',
    change_the_location: 'Changement la localisation',
    closed_status_changed_success: 'Modifications enregistrées',
    closed_status_changed_fail:
      'Une erreur est intervenue lors de la modification',
    sure_want_close_main_courante:
      'Étes-vous sûr de vouloir fermer la main courante ?',
    main_courante_flow_ratio: 'Ratio main courantes',
    inputs: 'Entrées',
    outputs: 'Sorties',
    ratio: 'Ratio',
    select_action_type: "Choisir Type d'action",
    update: 'Modifier',
    author: 'Auteur',
    success_update_main_courante_action: 'Action de la main courante modifiée',
    error_update_main_courante_action:
      'Erreur lors de la modification de l’action',
    success_update_main_courante: 'Main courante modifiée',
    error_update_main_courante:
      'Erreur lors de la modification de la main courante',
    sure_want_delete_main_courante:
      'Êtes-vous sûr de vouloir supprimer la main courante?',
    success_delete_main_courante: 'Main courante suprimée',
    error_delete_main_courante:
      'Erreur lors de la suppression de la main courante',
    sure_want_delete_main_courante_action:
      'Êtes-vous sûr de vouloir supprimer cette action de la main courante?',
    success_delete_main_courante_action: 'Action de la main courante supprimée',
    error_delete_main_courante_action:
      'Erreur lors de la suppression de cette action de la main courante',
    min: 'Min',
    max: 'Max',
    day: 'Jour',
    temperature: 'Température',
    wind_force: 'Force du vent',
    maximum_burst: 'Rafale maximale',
    forces_with_bursts: 'Forces avec rafales',
    maximum_accumulation_hour: 'Cumul maxi en 1h',
    day_accumulation: 'Cumul du jour',
    humidity: 'Humidité',
    visibility: 'Visibilité',
    pressure: 'Pression',
    hydrometeorology: 'Hydrométéorologie',
    thunderstorms: 'Orages',
    heat_wave: 'Canicule',
    wind: 'Vent',
    submersion_waves: 'Vagues Submersion',
    rains: 'Pluies',
    floods: 'Inondations',
    snow: 'Neige',
    upstream_floods: 'Crues Amont',
    downstream_floods: 'Crues Aval',
    vigilance: 'Vigilances',
    vigilance_red_alert_message:
      "Une vigilance absolue s'impose. Des phénomènes dangereux d'intensité exceptionnelle sont prévus. Tenez-vous régulièrement au courant de l'évolution de la situation et respectez impérativement les consignes de sécurité émises par les pouvoirs publics.",
    vigilance_yellow_alert_message:
      "Soyez très vigilant. Des phénomènes dangereux sont prévus. Tenez-vous au courant de l'évolution de la situation et suivez les conseils  de sécurité émis par les pouvoirs publics.",
    vigilance_light_yellow_alert_message:
      "Si vous pratiquez des activités sensibles au risque météorologique ou exposées aux crues, des phénomènes habituels dans la région mais occasionnellement et localement dangereux (ex. mistral, orage d'été, montée des eaux ) sont en effet prévus. Tenez-vous au courant de l'évolution de la situation.",
    vigilance_green_alert_message: 'Pas de vigilance particulière.',
    new_tab: 'Nouvel onglet',
    delete_dashboard_title: 'Are you sure you want to delete this dashboard?',
    oldest: 'Oldest',
    newest: 'Newest',
    sort_by: 'Sort by',
    rename_widget: 'Rename Widget',
    delete_widget: 'Delete Widget',
    rename_dashboard: 'Rename Dashboard',
    delete_dashboard: 'Delete Dashboard',
    last_edited: 'Last edited',
    name_your_dashboard: 'Name your dashboard',
    name_of_the_dashboard: 'Name of the dashboard',
    delete_widget_title: 'Are you sure you want to delete this widget?',
    widgets: 'Widgets',
    choose_widget: 'Choose a widget',
    choose_widget_type: 'Choose a $parent$ widget type',
    choose_data_input: 'Choose data input',
    configure_widget_directly: 'Configure the widget directly',
    configure_type_widget: 'Configure a $type$ configuration',
    choose_type_config: 'Choose an existing saved $type$ config',
    name_your_widget: 'Name your widget',
    name_of_the_widget: 'Name of the widget',
    add_new_widget: 'Add new widget',
    success_create_tab: 'Tab has been created',
    success_create_widget: 'Widget has been created',
    error_create_widget: 'Error on create widget',
    success_create_dashboard: 'dashboard has been created',
    error_create_dashboard: 'Error on create dashboard',
    success_delete_tab: 'Tab has been deleted',
    error_delete_tab: 'Error on delete tab',
    success_delete_widget: 'Widget has been deleted',
    error_delete_widget: 'Error on delete widget',
    success_delete_dashboard: 'dashboard has been deleted',
    error_delete_dashboard: 'Error on delete dashboard',
    success_update_tab: 'Tab has been updated',
    error_update_tab: 'Error on update tab',
    success_update_widgets: 'Widget has been updated',
    error_update_widgets: 'Error on update widget',
    success_update_dashboards: 'dashboard has been updated',
    error_update_dashboards: 'Error on update dashboard',
    delete_tab_modal_title: 'Are you sure you want to delete this tab?',
    custom_event_template: 'Custom event template',
    action_template: 'Action template',
    select_inputs_actions: 'Select inputs for the actions',
    inputs_selected: 'Inputs selected',
    next: 'Next',
    back: 'Back',
    finish: 'Finish',
    custom_event_config: 'Custom event config',
    create_new_input: 'Create new input',
    name_your_widget_config: 'Name your config',
    name_of_the_widget_config: 'Name of the config',
    success_update_widget_config: 'The widget config has been updated',
    error_update_widget_config: 'Error on update widget config',
  },
};
